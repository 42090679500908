import { Typography } from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import React from "react";
import { useTranslation } from "react-i18next";

interface BatteryChargingStatusTextProps {
  isFullCharge: boolean;
  isCharging: boolean;
  isLoading: boolean;
  isLowBattery: boolean;
  isLowTemperature: boolean;
  isInputPowerCutOff: boolean;
  isOutputPowerCutOff: boolean;
  chargingTime: string;
}

const BatteryChargingStatusText = ({
  isFullCharge,
  isCharging,
  isLoading,
  isLowBattery,
  isLowTemperature,
  isInputPowerCutOff,
  isOutputPowerCutOff,
  chargingTime,
}: BatteryChargingStatusTextProps) => {
  const { t } = useTranslation();

  if (isInputPowerCutOff) {
    return (
      <Typography
        variant="Body"
        htmlColor={LightColors.primary["1"]}
        dangerouslySetInnerHTML={{
          __html: t("Charging is limited").replaceAll("\n", "<br/>"),
        }}
      />
    );
  }

  if (isLowBattery && isCharging) {
    return (
      <>
        <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
          Charging complete in
        </Typography>
        <Typography variant="BodyBold" htmlColor={LightColors.secondary["11"]}>
          {chargingTime}
        </Typography>
      </>
    );
  }

  if (isFullCharge) {
    return (
      <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
        Fully charged
      </Typography>
    );
  }

  if (isLowTemperature && isCharging) {
    return (
      <>
        <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
          Charging complete in
        </Typography>
        <Typography variant="BodyBold" htmlColor={LightColors.primary["7"]}>
          {chargingTime}
        </Typography>
      </>
    );
  }

  if (isOutputPowerCutOff) {
    return (
      <Typography
        variant="Body"
        htmlColor={LightColors.primary["1"]}
        dangerouslySetInnerHTML={{
          __html: t("Power supplying is limited"),
        }}
      />
    );
  }

  if (isCharging) {
    return (
      <>
        <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
          Charging complete in
        </Typography>
        <Typography variant="BodyBold" htmlColor={LightColors.secondary["15"]}>
          {chargingTime}
        </Typography>
      </>
    );
  }

  return <></>;
};

export default BatteryChargingStatusText;
