import {
  BatteryConnectionFail,
  Typography,
} from "@thingsw/pitta-design-system";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";
import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  dimSupportDiv: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 70,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
  panelDiv: {
    position: "absolute",
    top: 72,
    left: 251,
    width: 375,
    height: "calc(100% - 158px)",
    backgroundColor: "white",
    borderRadius: 8,
    zIndex: 100,
    paddingTop: 56,
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: 0,
    },
  },
}));

interface BatteryConnectionFailPanelProps {
  title: string;
  onClick: () => void;
}

const BatteryConnectionFailPanel = (props: BatteryConnectionFailPanelProps) => {
  const { t } = useTranslation();

  const { onClick, title } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.dimSupportDiv}>
      <div className={classes.panelDiv}>
        <BatteryConnectionFail
          style={{
            width: 64,
            height: 64,
            marginBottom: 14,
          }}
        />
        <Typography
          style={{
            marginBottom: 14,
          }}
          variant="H6"
          htmlColor={LightColors.secondary[11]}
        >
          {title}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: 16,
          }}
        >
          <Typography
            variant="Small"
            htmlColor={LightColors.primary[2]}
            dangerouslySetInnerHTML={{
              __html: t("The paired dashcam has been disconnected").replaceAll(
                "\n",
                "<br/>"
              ),
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "rgba(247, 213, 215, 0.35)",
            borderRadius: 12,
            border: "1px solid rgba(244, 192, 195, 1)",
            padding: "16px 24px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 8,
            marginBottom: 52,
          }}
        >
          <Typography
            variant="SmallBold"
            dangerouslySetInnerHTML={{
              __html: t(
                "Check the Cloud connection of the paired dashcam"
              ).replaceAll("\n", "<br/>"),
            }}
          />
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(247, 213, 215, 1)",
              height: 1,
            }}
          ></div>
          <Typography
            variant="SmallBold"
            dangerouslySetInnerHTML={{
              __html: t(
                "Check if the battery is not fully discharged"
              ).replaceAll("\n", "<br/>"),
            }}
          />
        </div>
        <button
          style={{
            backgroundColor: "inherit",
            border: `1px solid ${LightColors.primary[6]}`,
            display: "block",
            width: "100%",
            height: 48,
            borderRadius: 12,
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <Typography variant="SmallBold">{t("Back to list")}</Typography>
        </button>
      </div>
    </div>
  );
};

export default BatteryConnectionFailPanel;
