import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  Theme,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  IconButton,
  Typography,
  theme as TWTheme,
} from "@thingsw/pitta-design-system";

import { DRAWER_MODE } from "../EventMapDrawer";
import { EventMap } from "../maps/EventMap";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import clsx from "clsx";
import Divider from "@material-ui/core/Divider";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import PauseIcon from "@material-ui/icons/Pause";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import EditIcon from "@material-ui/icons/Edit";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { ProgressSlider } from "../ProgressSlider";
import _ from "lodash";
import moment from "moment";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";

// import { PAYMENT } from "../../features/Payment/slice";
import { setSelectedHashtags } from "../../features/VOD/slice";
import { MobileDrawer } from "../MobileDrawer";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { eventToText } from "../../utils/VOD";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import Blackvuelogo from "../../lottiefiles/Blackvuelogo.json";
import { detect } from "detect-browser";
import ReactDOMServer from "react-dom/server";
import { THEME } from "../../features/Theme/slice";
import { getTextHeight } from "../../utils/Text";
import { PAYMENT } from "../../features/Payment/slice";
import FileTypeContainer from "@thingsw/pitta-design-system/dist/components/FileTypeContainer";
import { eventmapAxiosInst } from "../../utils";
import {
  LightColors,
  EventVideo,
  MTYPE_TO_ECODE,
  EventCode,
  FileTypeName,
  AI_EVENTS,
} from "@thingsw/pitta-modules";

//tab기준
const tablet = 660;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up(tablet)]: {
      minWidth: 470,
      maxWidth: 593,
      borderRadius: 12,
    },
  },
  content: {
    flex: 1,
  },
  drawerDiv: {
    height: "100%",
    boxSizing: "border-box",
    padding: "32px 20px",
    [theme.breakpoints.up(tablet)]: {
      padding: "32px 16px",
    },
  },
  drawerDivOpen: {
    overflow: "auto",
  },
  favoriteDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  noHashtag: {
    justifyContent: "end",
  },
  hashtagDiv: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  editIconBtn: {
    // alignItems: "flex-start",
    alignItems: "center",
  },
  editIcon: {
    color: LightColors.primary["2"],
  },
  formDiv: {
    margin: 0,
    alignItems: "self-start",
  },
  favoriteCheck: {
    padding: 0,
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    [theme.breakpoints.up(tablet)]: {
      marginRight: 6,
    },
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
    margin: "22px 0px 8px 4px",
    justifyContent: "space-between",
    [theme.breakpoints.up(tablet)]: {
      margin: "18px 4px 12px 4px",
    },
  },
  description: {
    wordBreak: "break-word",
    whiteSpace: "pre-line",
    marginTop: 20,
    [theme.breakpoints.up(tablet)]: {
      margin: "20px 0px 12px 0px",
    },
  },
  descriptionClose: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  favoriteCountIcon: {
    color: LightColors.primary["4"],
    fontSize: 16,
  },
  divider: {
    width: 1,
    height: 10,
  },

  // 비디오 css
  videoDiv: {
    position: "relative",
    // 16:9비율 사이즈
    paddingTop: "56.25%",
  },
  videoFullDiv: {
    paddingTop: "100vh",
    width: "179vh",
    margin: "auto",
  },
  videoFullIosDiv: {
    paddingTop: "unset",
    width: "100%",
    height: "100vh",
    margin: "auto",
  },
  videoDiv2: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "fill",
    minHeight: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // height: "31.5vh",
    [theme.breakpoints.up(tablet)]: {
      // height: "26.3vh",
    },
  },
  arrowIos15: {
    height: "100%",
    display: "flex",
    background: "rgba(19, 19, 28, 0.1)",
  },
  videoIos15: {
    // ios에서 objectFit:"fill"이 적용 안됨...
    // 모델별로 영상별로 비율이 다른데, 이벤트에서는 디바이스 모델을 알 수 없어서,
    // 강제로 비율을 변경할 수 없음...
    // https://developer.apple.com/forums/thread/709099
    // transform: "scale(1.33,1)",
  },
  videoIos: {
    // transform: "scale(1.22,1)",
  },
  videoIos13Full: {
    // transform: "scale(1,0.9)",
  },
  videoIosOthersFull: {
    // transform: "scale(0.8,0.8)",
  },
  // controlDiv: {
  //   position: "absolute",
  //   top: -2,
  //   left: -6,
  //   right: 0,
  //   bottom: 0,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   [theme.breakpoints.up(tablet)]: {
  //     top: -2,
  //     left: 0,
  //   },
  // },

  controlDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconBtn: {
    marginRight: 80,
    marginLeft: 80,
  },
  icon: {
    // mantis - 10420, Event Map 관리자에 의해 제거된 영상을 재생했을 경우 노출되는 문구 우측으로 치우쳐 보이는 이슈 수정, 모바일(Leehj)
    width: 30,
    height: 30,
    fill: "white",
    cursor: "pointer",
    "&:hover": {},
    [theme.breakpoints.up(tablet)]: {
      width: 48,
      height: 48,
    },
  },
  videoBlockText: {
    ...(theme.direction === "rtl"
      ? { margin: "0 8px 0 0" }
      : { margin: "0 0 0 8px" }),
    [theme.breakpoints.up(tablet)]: {
      ...(theme.direction === "rtl"
        ? { margin: "0 32px 0 0" }
        : { margin: "0 0 0 32px" }),
    },
  },
  arrowIcon: {
    width: 24,
    height: 24,
    fill: "white",
    cursor: "pointer",
  },
  videoTimeBarDiv: {
    display: "flex",
  },
  videoSmallIcon: {
    fill: "white",
    marginRight: 6,
    marginTop: -2,
    marginLeft: 3,
    [theme.breakpoints.up(tablet)]: {
      height: "26.3vh",
      marginLeft: 0,
    },
  },

  progressThumb: {
    color: "white",
    width: 10,
    height: 10,
    marginTop: -4,
  },
  track: {
    color: "white",
    height: 2,
  },
  rail: {
    color: "white",
    opacity: 1,
    height: 2,
  },

  scrollbarStyle: {
    // height: "100%",
    flex: 1,
    [theme.breakpoints.up(tablet)]: {
      overflowX: "hidden",
      // maxHeight: "calc(100vh - 100px)",
    },
  },
  videoLoadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: `${LightColors.primary["1"]}`,
    display: "flex",
    justifyContent: "center",
  },
  fullscreenFixed: {
    position: "fixed",
  },
}));

interface VideoEventInfoProps {
  eventVideos: (EventVideo | undefined)[];
  length: number;
  videoIndx: number;
  onNext?: VoidFunction;
  onPrev?: VoidFunction;
  onHiddenScroll?: (hidden: boolean) => void;
  onUpdateVideoRef?: (ref: HTMLVideoElement) => void;
  onEdit?: VoidFunction;
  fullscreen?: boolean;
  shareToEventmapPerm?: boolean;
  blocked?: boolean;
  removed?: boolean;
  mapType: "map" | "satellite";
}
export const VideoEventInfo = ({
  eventVideos,
  length,
  videoIndx,
  onNext,
  onPrev,
  onHiddenScroll,
  onUpdateVideoRef,
  onEdit,
  fullscreen,
  shareToEventmapPerm,
  blocked,
  removed,
  mapType,
}: VideoEventInfoProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(660));
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [favLoading, setFavLoading] = useState(false);
  const [feature, setFeature] = useState<GeoJSON.Feature>();
  const [drawerMode, setDrawerMode] = useState<DRAWER_MODE>("close");
  const [liked, setLiked] = useState<boolean>(false);
  const [hideCtrl, setHideCtrl] = useState(false);
  const [clickPlayer, setClickPlayer] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventVideo>();
  const [videoBlocked, setVideoBlocked] = useState(false);
  const [videoRemoved, setVideoRemoved] = useState(false);
  const [videoNotReady, setVideoNotReady] = useState(false);

  const [videoSrc, setVideoSrc] = useState<string>();
  const [view, setView] = useState(0);
  const [like, setLike] = useState(0);
  const [currentPlayTime, setCurrentPlayTime] = useState(0);

  // video관련
  const dispatch = useDispatch();
  const [
    videoRefCurrent,
    setVideoRefCurrent,
  ] = useState<HTMLVideoElement | null>(null);
  const [paused, setPaused] = useState(true);
  const [current, setCurrent] = useState<moment.Duration>(
    moment.duration(0, "s")
  );
  const [duration, setDuration] = useState<moment.Duration>(
    moment.duration(0, "s")
  );

  const { direction, fontFamily } = useSelector(
    (state: RootState) => state[THEME]
  );

  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  useEffect(() => {
    if (blocked !== undefined) {
      setVideoBlocked(blocked);
      if (blocked) {
        setLoading(false);
      }
    }
  }, [blocked]);

  useEffect(() => {
    if (removed !== undefined) {
      setVideoRemoved(removed);
      if (removed) {
        setLoading(false);
      }
    }
  }, [removed]);

  // const selectedEvent = useMemo(() => {
  //   if (eventVideos.length <= videoIndx) return;
  //   return eventVideos[videoIndx];
  // }, [eventVideos, videoIndx]);

  const ios15 = useMemo(() => {
    const browser = detect();
    console.log("browser", browser, navigator.userAgent);
    return (
      browser?.name === "ios-webview" &&
      navigator.userAgent.indexOf("iPhone OS 15") > -1
    );
  }, []);

  const ios = useMemo(() => {
    const browser = detect();
    console.log("browser", browser);
    return browser?.name === "ios-webview";
  }, []);

  const video = useMemo(() => {
    return eventVideos[videoIndx];
  }, [eventVideos, videoIndx]);

  const handleSaveView = useCallback(async () => {
    if (selectedEvent && !selectedEvent.owner) {
      //TODO: 내영상은 카운트 안되게 수정
      await eventmapAxiosInst.post(`/event-videos/${selectedEvent.id}/view`);
      setView((v) => v + 1);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (videoRefCurrent) {
      videoRefCurrent.currentTime = currentPlayTime;
    }
  }, [videoRefCurrent, currentPlayTime]);
  useEffect(() => {
    if (videoRefCurrent) {
      videoRefCurrent.onloadedmetadata = (e) => {
        handleSaveView();
      };
      return () => {
        videoRefCurrent.onloadedmetadata = null;
      };
    }
  }, [handleSaveView, videoRefCurrent]);

  useEffect(() => {
    if (videoRefCurrent) {
      onUpdateVideoRef?.(videoRefCurrent);
    }
  }, [onUpdateVideoRef, videoRefCurrent]);

  useEffect(() => {
    if (!hideCtrl && !paused) {
      const timerId = setTimeout(() => {
        console.log("Eventmap", "setHideCtrl");
        setHideCtrl(true);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [hideCtrl, paused]);

  useEffect(() => {
    const getFeature = async () => {
      if (!userProfile || !subscriptionInfo) return;
      if (!video) return;
      if (video.blocked) {
        setFeature(undefined);
        setLoading(false);
        setSelectedEvent(video);
        if (videoRefCurrent) {
          //@ts-ignore
          videoRefCurrent.src = undefined;
        }
        setVideoBlocked(true);

        return;
      }

      let query = "";

      if (
        userProfile.userType === "SubMaster" ||
        userProfile.userType === "User"
      ) {
        query = `email=${subscriptionInfo.masterEmail}`;
      }
      console.log("Eventmap", "feature videos", video);
      const evt = await eventmapAxiosInst.get(
        `/events/${video.eventId}/feature?${query}`
      );
      const geojson = evt.data.geojson;
      const found = _.find(evt.data.videos, (v) => v.id === video.id);
      geojson.properties.thumb1 = found.thumb;
      geojson.properties.hasVideo = !!found;
      setFeature(geojson);
      console.log("Eventmap", "getFeature", geojson, "found", found);
      if (!found) {
        setLoading(false);
        setVideoBlocked(true);
      } else {
        try {
          const url = await eventmapAxiosInst.get(
            `/event-videos/${video.id}/presignedUrl`
          );
          setVideoSrc(url.data.url);
        } catch (e: any) {
          const axiosError = e as AxiosError;
          console.log("error", "presignedUrl", axiosError.response?.status);
          //아직 빅데이터 s3로 복사 안됨
          if (axiosError.response?.status === 428) {
            setVideoNotReady(true);
            setLoading(false);
          }
        } finally {
          setSelectedEvent(found);
        }
      }
    };

    getFeature();
  }, [
    eventVideos,
    subscriptionInfo,
    userProfile,
    video,
    videoIndx,
    videoRefCurrent,
    videoRefCurrent?.src,
  ]);

  useEffect(() => {
    if (!selectedEvent) return;
    setView(selectedEvent.view);
    setLike(selectedEvent.like);
    setLiked(selectedEvent.liked);
    setVideoBlocked(selectedEvent.blocked);
  }, [selectedEvent]);

  const handlePlay = useCallback(() => {
    if (videoRefCurrent?.paused) {
      videoRefCurrent?.play();
    } else {
      videoRefCurrent?.pause();
    }
  }, [videoRefCurrent]);

  useEffect(() => {
    if (videoRefCurrent) {
      videoRefCurrent.ontimeupdate = () => {
        setCurrent(
          moment.duration(_.round(videoRefCurrent.currentTime, 0), "s")
        );
        // console.log("timeupdate");
      };
      videoRefCurrent.ondurationchange = () => {
        let dur = moment.duration(_.round(videoRefCurrent.duration, 0), "s");
        setDuration(dur);
        // console.log("durationchange");
      };
      videoRefCurrent.onplay = () => {
        setPaused(false);
        // setLoading(false);
        // console.log("VideoEventInfo", "onplay");
      };
      videoRefCurrent.onplaying = () => {
        // setPaused(false);
        setLoading(false);
        // console.log("VideoEventInfo", "onplaying");
      };
      videoRefCurrent.onpause = () => {
        setPaused(true);
        setHideCtrl(false);
        console.log("pause");
      };
    }
  }, [handleSaveView, videoRefCurrent, current]);

  const handleSaveFavorite = useCallback(async () => {
    if (selectedEvent) {
      setFavLoading(true);
      try {
        await eventmapAxiosInst.post(
          `/event-videos/${selectedEvent.id}/like`,
          {}
        );
        if (liked) {
          setLike((l) => l - 1);
        } else {
          setLike((l) => l + 1);
        }
        setLiked((o) => !o);
      } catch (e) {
      } finally {
        setFavLoading(false);
      }
    }
  }, [liked, selectedEvent]);

  const hashtagsMarkup = useMemo(() => {
    let text = "";
    if (selectedEvent) {
      if ((selectedEvent.hashtags?.length ?? 0) > 0) {
        text = _.chain(selectedEvent.hashtags)
          // .orderBy(["order"], ["asc"])
          .map((tag) => tag.name)
          .join("  ")
          .value();
      } else if (feature?.properties) {
        text = t(eventToText(feature.properties.mode, feature.properties.type));
      }
    }
    return (
      <div className={clsx(drawerMode === "close" && classes.hashtagDiv)}>
        <Typography
          category="Default"
          variant="H5"
          htmlColor={LightColors.primary["2"]}
        >
          {text}
        </Typography>
      </div>
    );
  }, [classes.hashtagDiv, drawerMode, feature?.properties, selectedEvent, t]);

  const editFavMarkup = useMemo(() => {
    if (videoBlocked || videoRemoved || videoNotReady) return;
    if (selectedEvent) {
      if (selectedEvent.owner) {
        if (!shareToEventmapPerm) return;
        return (
          <IconButton
            className={classes.editIconBtn}
            onClick={() => {
              // setOpenEventShareModal(true);
              onEdit?.();
              videoRefCurrent?.pause();
              onHiddenScroll?.(true);
            }}
          >
            <EditIcon className={classes.editIcon} />
          </IconButton>
        );
      } else {
        return (
          <FormControlLabel
            className={classes.formDiv}
            control={
              <Checkbox
                checked={liked}
                onChange={favLoading ? undefined : handleSaveFavorite}
                className={classes.favoriteCheck}
                icon={<FavoriteBorderIcon />}
                checkedIcon={<FavoriteIcon />}
                name="checkedH"
                disableRipple
              />
            }
            label=""
          />
        );
      }
    }
  }, [
    videoBlocked,
    videoRemoved,
    videoNotReady,
    selectedEvent,
    shareToEventmapPerm,
    classes.editIconBtn,
    classes.editIcon,
    classes.formDiv,
    classes.favoriteCheck,
    onEdit,
    videoRefCurrent,
    onHiddenScroll,
    liked,
    favLoading,
    handleSaveFavorite,
  ]);

  const drawerBodyMarkup = useMemo(() => {
    let eventType =
      selectedEvent && (MTYPE_TO_ECODE[selectedEvent.type] as EventCode);
    // mantis - 10478 모바일 처음에 parking인 영상진입시 Event칩으로 보이는 이슈 수정(Leehj)
    let fileType = "" as FileTypeName;
    // 공유영상재생시에도 normal/parking 이벤트 chip 교체 (Leehj) 23.01.12
    if (
      selectedEvent &&
      selectedEvent.mode === "P" &&
      // selectedEvent.type의 타입은 number이고 selectedEvent.type값의 실제 타입은 string으로 나와서 `${selectedEvent.type}`로 비교(Leehj) 23.01.12
      `${selectedEvent.type}` !== "0"
    ) {
      eventType = "I";
      fileType = "Event";
    }
    if (
      selectedEvent &&
      selectedEvent.mode === "P" &&
      `${selectedEvent.type}` === "0"
    ) {
      fileType = "Parking";
    }
    if (selectedEvent && selectedEvent.mode === "M") {
      // mantis 10464, Manual 공유 영상 재생화면 확인 시 Manual칩만 노출되도록 수정(Leehj)
      // eventType = "M";
      fileType = "Manual";
    }
    if (selectedEvent && selectedEvent.mode === "N") {
      fileType = "Normal";
    }
    if (selectedEvent && (selectedEvent.mode === "E" || selectedEvent.mode === "J")) {
      fileType = "Event";
    }

    const body = (
      <div
        className={clsx(classes.drawerDiv, {
          [classes.drawerDivOpen]: drawerMode === "open",
        })}
      >
        <div
          className={clsx(classes.favoriteDiv, {
            [classes.noHashtag]: hashtagsMarkup === undefined,
          })}
        >
          {hashtagsMarkup}

          <div style={{ display: "flex", alignItems: "flex-start" }}>
            {editFavMarkup}
          </div>
        </div>
        <div className={classes.infoDiv}>
          <div>
            <FileTypeContainer
              fileType={fileType}
              eventCode={eventType}
              isAI={_.includes(AI_EVENTS, eventType)}
              t={t}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FavoriteIcon
              className={classes.favoriteCountIcon}
              style={{ marginRight: 2 }}
            />
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["2"]}
              style={{ marginRight: 8 }}
            >
              {like} like
            </Typography>
            <Divider className={classes.divider} />
            <RemoveRedEyeOutlinedIcon
              className={classes.favoriteCountIcon}
              style={{ marginRight: 2, marginLeft: 8 }}
            />
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["2"]}
            >
              {view} views
            </Typography>
          </div>
        </div>

        <div
          className={clsx(classes.description, {
            [classes.descriptionClose]: drawerMode === "close",
          })}
        >
          {selectedEvent?.description}
        </div>
      </div>
    );
    if (drawerMode === "open") {
      return mobile ? (
        body
      ) : (
        <SimpleBarReact style={{ height: "100%" }}>{body}</SimpleBarReact>
      );
    }
    return body;
  }, [
    classes.description,
    classes.descriptionClose,
    classes.divider,
    classes.drawerDiv,
    classes.drawerDivOpen,
    classes.favoriteCountIcon,
    classes.favoriteDiv,
    classes.infoDiv,
    classes.noHashtag,
    drawerMode,
    editFavMarkup,
    hashtagsMarkup,
    like,
    mobile,
    selectedEvent,
    t,
    view,
  ]);

  const closeHeight = useMemo(() => {
    const tmp = ReactDOMServer.renderToString(
      <ThemeProvider
        theme={TWTheme({
          fontFamily,
          dir: direction,
          Colors: LightColors,
          breakpoints: {
            values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
          },
        })}
      >
        {hashtagsMarkup}
      </ThemeProvider>
    );
    const height = getTextHeight(tmp, [classes.root]) + 48;
    const offset = mobile ? 60 : 70;

    if (selectedEvent?.description) {
      return height - offset;
    }
    return height - 80;
  }, [
    classes.root,
    direction,
    fontFamily,
    hashtagsMarkup,
    mobile,
    selectedEvent?.description,
  ]);

  const playBtnMarkup = useMemo(() => {
    console.log("playBtnMarkup", selectedEvent);
    if (videoBlocked || videoRemoved || videoNotReady) {
      let message = "This video has been reported_";
      if (videoRemoved) {
        message = "This video has been removed.";
      }
      if (videoNotReady) {
        message =
          "It looks like the video file is being prepared. please try again later.";
      }
      return (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon
              style={{ width: 54, height: 54, strokeWidth: 3 }}
              htmlColor="#FFFFFF"
            />
          </div>
          <div
            className={classes.videoBlockText}
            style={{
              width: 240,
              // margin: theme.direction === "rtl" ? "0 32px 0 0" : "0 0 0 32px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {videoNotReady && (
              <Typography category="Default" variant="H1" htmlColor="#FFFFFF">
                {t("Oops!")}
              </Typography>
            )}
            <Typography category="Default" variant="Body" htmlColor="#FFFFFF">
              {t(message)}
            </Typography>
          </div>
        </div>
      );
    }
    if (paused) {
      return (
        <IconButton>
          <PlayCircleFilledIcon className={classes.icon} onClick={handlePlay} />
        </IconButton>
      );
    }
    return (
      <IconButton>
        <PauseIcon className={classes.icon} onClick={handlePlay} />
      </IconButton>
    );
  }, [
    selectedEvent,
    videoBlocked,
    videoRemoved,
    videoNotReady,
    paused,
    classes.icon,
    classes.videoBlockText,
    handlePlay,
    t,
  ]);

  return (
    <div className={classes.content}>
      <div>
        <div
          onMouseEnter={() => {
            console.log("Eventmap", "onMouseEnter");
            !paused && setHideCtrl(false);
          }}
          onMouseLeave={() => {
            setClickPlayer(false);
          }}
          onMouseMove={() => {
            // console.log("Eventmap", "onMouseMove");
            !clickPlayer && !mobile && !paused && setHideCtrl(false);
          }}
          onClick={(e) => {
            e.stopPropagation();
            console.log("Eventmap", "onClick", paused);
            setClickPlayer(true);
            !paused && setHideCtrl((h) => !h);
          }}
          className={clsx(classes.videoDiv, {
            [classes.videoFullDiv]: !ios && fullscreen,
            [classes.videoFullIosDiv]: ios && fullscreen,
          })}
        >
          <div className={classes.video}>
            <video
              className={clsx(classes.video, {
                [classes.videoIos15]: ios15,
              })}
              ref={(ref) => {
                setVideoRefCurrent(ref);
              }}
              src={
                videoBlocked || videoRemoved || videoNotReady
                  ? undefined
                  : videoSrc
              }
              poster={
                videoBlocked || videoRemoved || videoNotReady
                  ? undefined
                  : selectedEvent?.thumb
              }
              muted
              autoPlay
              playsInline
              webkit-playsinline
            />
            {loading && (
              <div className={classes.videoLoadingDiv}>
                <Lottie animationData={Blackvuelogo} loop={true} />
              </div>
            )}
            {(videoBlocked || videoRemoved || videoNotReady) && (
              <div className={classes.videoLoadingDiv}></div>
            )}
          </div>

          {!loading && !hideCtrl && (
            <div className={classes.controlDiv}>
              {_.findIndex(eventVideos, (c) => c?.id === selectedEvent?.id) ===
                0 || eventVideos.length === 0 ? (
                // mantis - 10420, Event Map 관리자에 의해 제거된 영상을 재생했을 경우 노출되는 문구 우측으로 치우쳐 보이는 이슈 수정, 모바일(Leehj)
                <div style={{ minWidth: mobile ? 30 : 48 }}></div>
              ) : (
                <div
                  className={clsx({
                    [classes.arrowIos15]: ios15,
                  })}
                >
                  <IconButton
                    disabled={
                      _.findIndex(
                        eventVideos,
                        (c) => c?.id === selectedEvent?.id
                      ) === 0
                    }
                  >
                    <NavigateBeforeIcon
                      className={classes.icon}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onPrev?.();
                        setPaused(true);
                        setLiked(false);
                        setLoading(true);
                        setVideoBlocked(false);
                        setVideoRemoved(false);
                        setVideoNotReady(false);
                        dispatch(setSelectedHashtags([]));
                      }}
                    />
                  </IconButton>
                </div>
              )}

              {playBtnMarkup}

              {_.findIndex(eventVideos, (c) => c?.id === selectedEvent?.id) ===
              length - 1 ? (
                // mantis - 10420, Event Map 관리자에 의해 제거된 영상을 재생했을 경우 노출되는 문구 우측으로 치우쳐 보이는 이슈 수정, 모바일(Leehj)
                <div style={{ minWidth: mobile ? 30 : 48 }}></div>
              ) : (
                <div
                  className={clsx({
                    [classes.arrowIos15]: ios15,
                  })}
                >
                  <IconButton>
                    <NavigateNextIcon
                      className={classes.icon}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onNext?.();
                        setPaused(true);
                        setLiked(false);
                        setLoading(true);
                        setVideoBlocked(false);
                        setVideoRemoved(false);
                        setVideoNotReady(false);
                        dispatch(setSelectedHashtags([]));
                      }}
                    />
                  </IconButton>
                </div>
              )}
            </div>
          )}

          <div
            style={{
              position: fullscreen ? "fixed" : "absolute",
              inset: 0,
              top: "auto",
              bottom: 0,
              left: mobile || fullscreen ? 0 : 7.5,
              width: mobile || fullscreen ? "100%" : "96.5%",
              backgroundColor: fullscreen ? "rgba(0,0,0,0.3)" : undefined,
            }}
          >
            <ProgressSlider
              classes={{ thumb: classes.progressThumb }}
              value={
                (current.asSeconds() / Math.max(1, duration.asSeconds())) * 100
              }
              onChange={(_e, newVal) => {
                const val = ((newVal as number) - 0.5) / 99;
                setCurrentPlayTime(val * duration.asSeconds());
              }}
              aria-labelledby="continuous-slider"
            />
          </div>
        </div>
      </div>
      {!fullscreen && (
        <>
          <div
            style={{
              width: "100%",
              height: mobile
                ? `calc(100% - ${videoRefCurrent?.clientHeight ?? 0}px)`
                : `calc(min(100vh - ${
                    (videoRefCurrent?.clientHeight ?? 0) + 50
                  }px, 455px))`,
            }}
          >
            <EventMap
              singleEvent
              event={feature}
              name="Modal"
              initMapType={mapType}
            />
          </div>

          <MobileDrawer
            mode="eventmap"
            initMode="close"
            offset={videoRefCurrent?.clientHeight ?? 0}
            closeHeight={closeHeight}
            // eventModal
            onDrawerMode={(mode) => setDrawerMode(mode)}
            disableSwipe={
              (selectedEvent?.hashtags?.length ?? 0) === 0 &&
              !selectedEvent?.description
            }
            mobile={mobile}
          >
            {drawerBodyMarkup}
          </MobileDrawer>
        </>
      )}
    </div>
  );
};
