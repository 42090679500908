import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  makeStyles,
  Theme,
  Popper,
  PopperPlacementType,
  Paper,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import { ReferenceObject, LightColors } from "@thingsw/pitta-modules";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Button,
  CheckBox,
  Typography,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { useTranslation } from "react-i18next";
import VideocamIcon from '@material-ui/icons/Videocam';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: 346,
    borderRadius: 12,
  },
  header: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  container: {
    padding: theme.spacing(1, 3, 1, 3),
  },
  title: {
    marginBottom: 8,
  },
  discription: {
    marginBottom: 8,
  },
  menuTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  menuIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    color: LightColors.primary["3"],
    fontSize: 20,
  },
  settingCheckBoxDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: -3,
    ...(theme.direction === "rtl"
      ? {
        padding: theme.spacing(0, 3, 1.5, 2),
      }
      : {
        padding: theme.spacing(0, 2, 1.5, 3),
      }),
  },
  checkBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  checkBoxLabelText: {
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
  speedInputDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
    "& label": {
      marginBottom: 0,
    },
  },
  speedInput: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      padding: "6px 13px 6px 16px",
      borderRadius: 8,
    },
    "& input": {
      height: 16,
      width: 32,
    },
    "& > span": {
      marginLeft: 8,
    },
  },
  btnDiv: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "&>button": {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(2) }
        : { marginLeft: theme.spacing(2) }),
    },
  },
  checkBoxDiv: {
    marginBottom: 8,
  },
  liveEventDiv: {
    marginBottom: 16,
    paddingLeft: 16,
  },
  divider: {
    width: "100%",
    height: 1,
    marginBottom: 16,
    backgroundColor: "#E9E9EA",
  },
}));

export interface IBoxProSettings {
  checkEnterSdCard?: boolean;
  checkEnterSdCardLiveEvent?: boolean;
  checkExitSdCard?: boolean;
  checkExitSdCardLiveEvent?: boolean;
  checkPassSdCard?: boolean;
  checkPassLiveEventUpload?: boolean;
  checkSpeedLiveEvent?: boolean;
  checkSpeed?: boolean;
  alarmSpeed?: string;
}

interface GeofenceBoxProSettingPopperProps {
  open: boolean;
  mobile?: boolean;
  onClickAway: (event: React.MouseEvent<Document>) => void;
  onClose: () => void;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  placement?: PopperPlacementType;
  modifiers?: object;
  drawingMode: string;
  velocityUnit?: string;
  preSettings?: IBoxProSettings;
  onSettings: (settings?: IBoxProSettings) => void;
}

export const GeofenceBoxProSettingPopper = (props: GeofenceBoxProSettingPopperProps) => {
  const {
    open,
    onClickAway,
    anchorEl,
    onClose,
    onSettings,
    placement,
    modifiers,
    drawingMode,
    velocityUnit,
    preSettings,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [boxProSettings, setBoxProSettings] = useState<IBoxProSettings>();
  const speedInputRef = useRef<any>(null);  

  useEffect(() => {
    if (!open) return;
    setBoxProSettings(preSettings);
  }, [open, preSettings]);

  const setPreSettings = useCallback(() => {
    setBoxProSettings(preSettings);
  }, [preSettings]);

  useEffect(() => {
    if (!boxProSettings) return;
    if (boxProSettings?.checkSpeed && speedInputRef.current) speedInputRef.current.focus();
  }, [boxProSettings]);

  const boxProSettingsMarkup = useMemo(() => {
    return (
      <>
        <div className={classes.settingCheckBoxDiv}>
          {drawingMode !== "polyline" && (
            <>
              <div className={classes.checkBoxDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      checked={boxProSettings?.checkEnterSdCard}
                      onChange={(e, checked) => {
                        setBoxProSettings({
                          ...boxProSettings,
                          checkEnterSdCard: checked,
                          checkEnterSdCardLiveEvent: checked === false && false,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Entry")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.liveEventDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={!boxProSettings?.checkEnterSdCard}
                      checked={boxProSettings?.checkEnterSdCardLiveEvent}
                      onChange={(e, checked) => setBoxProSettings({ ...boxProSettings, checkEnterSdCardLiveEvent: checked })}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Live Event Upload")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}
          {drawingMode !== "polyline" && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.checkBoxDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      checked={boxProSettings?.checkExitSdCard}
                      onChange={(e, checked) => {
                        setBoxProSettings({
                          ...boxProSettings,
                          checkExitSdCard: checked,
                          checkExitSdCardLiveEvent: checked === false && false,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Exit")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.liveEventDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={!boxProSettings?.checkExitSdCard}
                      checked={boxProSettings?.checkExitSdCardLiveEvent}
                      onChange={(e, checked) => setBoxProSettings({ ...boxProSettings, checkExitSdCardLiveEvent: checked })}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Live Event Upload")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}
          {drawingMode === "polyline" && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.checkBoxDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      checked={boxProSettings?.checkPassSdCard}
                      onChange={(e, checked) => {
                        setBoxProSettings({
                          ...boxProSettings,
                          checkPassSdCard: checked,
                          checkPassLiveEventUpload: checked === false && false,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Pass")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.liveEventDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={!boxProSettings?.checkPassSdCard}
                      checked={boxProSettings?.checkPassLiveEventUpload}
                      onChange={(e, checked) => setBoxProSettings({ ...boxProSettings, checkPassLiveEventUpload: checked })}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Live Event Upload")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}
          {drawingMode !== "polyline" && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.speedInputDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      checked={boxProSettings?.checkSpeed ?? false}
                      onChange={(e, checked) => {
                        if (speedInputRef.current.value !== "") {
                          setBoxProSettings({
                            ...boxProSettings,
                            checkSpeed: false,
                            alarmSpeed: "",
                            checkSpeedLiveEvent: false,
                          });
                        } else {
                          setBoxProSettings({
                            ...boxProSettings,
                            checkSpeed: checked,
                            checkSpeedLiveEvent: false,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Speed alert_2")}
                    </Typography>
                  }
                />
                <div className={classes.speedInput}>
                  <Input
                    value={boxProSettings?.alarmSpeed ?? ""}
                    ref={speedInputRef}
                    onBlur={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        !boxProSettings?.alarmSpeed
                        || boxProSettings?.alarmSpeed === "0"
                      ) {
                        setTimeout(() => {
                          if (boxProSettings?.checkSpeed) {
                            setBoxProSettings({
                              ...boxProSettings,
                              alarmSpeed: "",
                              checkSpeed: false,
                              checkSpeedLiveEvent: false,
                            });
                          }
                        }, 100);
                      }
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      const cleanNumber = e.target.value.replace(/[^0-9]/g, "");
                      if ((Number(cleanNumber) > 0
                        && Number(cleanNumber) <= (velocityUnit === "0" ? 200 : 124))
                      ) {
                        setBoxProSettings({
                          ...boxProSettings,
                          alarmSpeed: cleanNumber,
                        });
                      } else if (cleanNumber === "") {
                        setBoxProSettings({
                          ...boxProSettings,
                          alarmSpeed: cleanNumber,
                          checkSpeedLiveEvent: false,
                        });
                      }
                    }}
                    disabled={!boxProSettings?.checkSpeed}
                  // error={unallowed}
                  />
                  <Typography
                    category="Default"
                    variant="Body"
                  >
                    {velocityUnit === "0" ? t("km/h") : t("MPH")}
                  </Typography>
                </div>
              </div>        
              <div className={classes.liveEventDiv}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={!(boxProSettings?.checkSpeed && boxProSettings?.alarmSpeed)}
                      checked={boxProSettings?.checkSpeedLiveEvent ?? false}
                      onChange={(e, checked) => {
                        setBoxProSettings({
                          ...boxProSettings,
                          checkSpeedLiveEvent: checked,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      className={classes.checkBoxLabelText}
                    >
                      {t("Live event upload")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }, [
    classes,
    drawingMode,
    boxProSettings,
    velocityUnit,
    t,
  ]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={modifiers}
      transition
    >
      <Paper
        classes={{ root: classes.root }}
        elevation={3}
      >
        <ClickAwayListener
          onClickAway={onClickAway}
        >
          <div>
            <div className={classes.header}>
              <div className={classes.title}>
                <Typography category="Default" variant="H6">
                  {t("Box Pro Models Settings")}
                </Typography>
              </div>
              <div className={classes.discription}>
                <Typography category="Default" variant="Body">
                  {t("Push notifications can be configured_")}
                </Typography>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.menuTitle}>
                <VideocamIcon className={classes.menuIcon} />
                <Typography category="Default" variant="BodyBold">
                  {t("Recording Video")}
                </Typography>
              </div>
              {boxProSettingsMarkup}
            </div>
            <div className={classes.btnDiv}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setPreSettings();
                  onClose();
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                // mantis - 14181, 그룹에 선택된 카메라가 있을 경우 활성화 (Hongcs, 24.07.30)
                // disabled={
                //   (_.isEqual(boxProSettings, preSettings)) ||
                //   (boxProSettings?.checkSpeed && !boxProSettings?.alarmSpeed)
                // }
                onClick={() => onSettings(boxProSettings)}
              >
                {t("Apply")}
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
