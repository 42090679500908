import React, { useState } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadNotificationEmailList } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { deleteEmail } from "../../apis";
import { Webviewer, RESULT_CODE } from "@thingsw/pitta-modules";

interface PersonDeleteModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onClickNegative: () => void;
  onClickPositive: () => void;
  deleteRecipients: string;
}

export const PersonDeleteModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
  deleteRecipients,
}: PersonDeleteModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      loading={loading}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      // 10629 - API 호출 완료까지 팝업 안닫히게 수정
      // API에 토큰 타입 추가
      onClickPositive={async () => {
        setLoading(true);
        try {
          if (camera && email && loginInfo) {
            const res = await deleteEmail(
              email,
              loginInfo.user_token,
              camera.psn,
              deleteRecipients,
              tokenType
            );

            const { resultcode } = res.data as {
              resultcode: RESULT_CODE;
              message: string;
            };
            if (resultcode === "BC_ERR_OK") {
              dispatch(loadNotificationEmailList(camera.psn));
              onClickPositive();
            }
          }
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }}
      heading={t("Delete")}
      close={true}
      content={
        <div style={{ padding: "-8px 24px 5px" }}>
          <Typography variant="Body" category="Default">
            {t("Are you sure_recipient")}
          </Typography>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Delete")}
      Secondary={true}
    />
  );
};
