import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, CheckCircle, Typography } from "@thingsw/pitta-design-system";
import React, { useMemo } from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Hashtag, LightColors } from "@thingsw/pitta-modules";

interface HashtagButtonProps {
  hashtag?: Hashtag;
  checked?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { borderRadius: 20, margin: 4 },
}));

export const HashtagButton = ({
  hashtag,
  checked,
  selected,
  onClick,
}: HashtagButtonProps) => {
  const classes = useStyles();
  const hashtagMarkup = useMemo(() => {
    if (hashtag) {
      let endIcon = <AddIcon fontSize="small" />;
      if (checked) {
        endIcon = <CheckCircle />;
      }
      if (selected) {
        endIcon = <CloseIcon fontSize="small" />;
      }
      return (
        <Button
          variant="outlined"
          className={clsx(classes.root)}
          endIcon={endIcon}
          color={checked || selected ? "gray-check" : "gray"}
          onClick={onClick}
          size="medium"
          style={{ height: 40 }}
        >
          {hashtag.name}
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        style={{ borderRadius: 20, height: 40, padding: "7px 20px 7px 16px" }}
        startIcon={<AddIcon fontSize="small" style={{ marginRight: 5 }} />}
        color="gray"
        onClick={onClick}
        size="medium"
      >
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          #hashtag
        </Typography>
      </Button>
    );
  }, [checked, classes.root, hashtag, onClick, selected]);
  return hashtagMarkup;
};
