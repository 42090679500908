import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  WebMenuItem,
  MobileMenu,
  Menu,
  ZoomPanel,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { detect } from "detect-browser";
import { useRef, useMemo, useCallback, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import * as turf from "@turf/turf";

import { toggleFullscreen } from "../../utils/GoogleMap";

import CheckIcon from "@material-ui/icons/Check";
import {
  GPS,
  ITrackInfo,
  loadGPSTrackingData,
  setFirstFitBounds,
  setSelectedDrive,
  setSelectedTrack,
} from "../../features/GPS/slice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "../../features/store";
import { USER, loadUserSettings } from "../../features/User/slice";
import { GEOFENCE } from "../../features/Geofence/slice";
import {
  ITrackData,
  LightColors,
  GPSTrackingIcons,
  Webviewer,
  DEFAULT_MAP_STYLE,
  DARKMODE_MAP_STYLE,
  NO_INT_MAP_STYLE,
  GPSTrackingInfoPanelOptions,
  makeAppGPSTrackingInfoPanel,
  makeGPSTrackingInfoPanel,
  DEFAULT_LOCATION,
  DEFAULT_ZOOM,
  SATELLITE_MAP_STYLE,
  initGeofenceSourcesAndLayers,
  updateGeofence,
  useScreenOrientation,
  useWindowSize,
} from "@thingsw/pitta-modules";
import { TopRightControl } from "@thingsw/pitta-liveview-module";
import React from "react";

import "mapbox-gl/dist/mapbox-gl.css";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { setLoadingLocation, THEME } from "../../features/Theme/slice";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const POLYLINE_COLOR = "#13131C";
// const POLYLINE_SATELLITE_COLOR = "#B7E1FF";

const GPS_TRACKING_LINE_SOURCE_NAME = "gps-tracking-line-source";
const GPS_TRACKING_LINE_LAYER_NAME = "gps-tracking-line-layer";
const GPS_TRACKING_OUTLINE_LAYER_NAME = "gps-tracking-outline-layer";
const GPS_TRACKING_DISCONNECTED_LINE_LAYER_NAME =
  "gps-tracking-disconnected-line-layer";
const GPS_TRACKING_DISCONNECTED_OUTLINE_LAYER_NAME =
  "gps-tracking-disconnected-outline-layer";

const GPS_TRACKING_OUTLINE_LAYER: mapboxgl.LineLayer = {
  id: GPS_TRACKING_OUTLINE_LAYER_NAME,
  type: "line",
  source: GPS_TRACKING_LINE_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "white",
    "line-width": {
      property: "selected",
      type: "categorical",
      default: 3,
      stops: [
        [true, 10],
        [false, 6],
      ],
    },
    "line-opacity": 0.7,
  },
  filter: ["==", ["get", "connected"], true],
};

const GPS_TRACKING_LINE_LAYER: mapboxgl.LineLayer = {
  id: GPS_TRACKING_LINE_LAYER_NAME,
  type: "line",
  source: GPS_TRACKING_LINE_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": POLYLINE_COLOR,
    "line-width": {
      property: "selected",
      type: "categorical",
      default: 3,
      stops: [
        [true, 6],
        [false, 3],
      ],
    },
    "line-opacity": 0.7,
  },
  filter: ["==", ["get", "connected"], true],
};

const GPS_TRACKING_DISCONNECTED_OUTLINE_LAYER: mapboxgl.LineLayer = {
  id: GPS_TRACKING_DISCONNECTED_OUTLINE_LAYER_NAME,
  type: "line",
  source: GPS_TRACKING_LINE_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "white",
    "line-width": {
      property: "selected",
      type: "categorical",
      default: 3,
      stops: [
        [true, 10],
        [false, 6],
      ],
    },
    "line-opacity": 0.7,
    "line-dasharray": [5, 5],
  },
  filter: ["==", ["get", "connected"], false],
};

const GPS_TRACKING_DISCONNECTED_LINE_LAYER: mapboxgl.LineLayer = {
  id: GPS_TRACKING_DISCONNECTED_LINE_LAYER_NAME,
  type: "line",
  source: GPS_TRACKING_LINE_SOURCE_NAME,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": POLYLINE_COLOR,
    "line-width": {
      property: "selected",
      type: "categorical",
      default: 3,
      stops: [
        [true, 6],
        [false, 3],
      ],
    },
    "line-opacity": 0.7,
    "line-dasharray": [5, 5],
  },
  filter: ["==", ["get", "connected"], false],
};

const EVENT_SOURCE_NAME = "event-source";
const EVENT_DIR_LAYER_NAME = "event-dir-layer";
const EVENT_END_LAYER_NAME = "event-end-layer";
const EVENT_START_LAYER_NAME = "event-start-layer";
const EVENT_DETAIL_LAYER_NAME = "event-detail-layer";

const EVENT_DIR_LAYER: mapboxgl.SymbolLayer = {
  id: EVENT_DIR_LAYER_NAME,
  source: EVENT_SOURCE_NAME,
  type: "symbol",
  paint: {
    "icon-opacity-transition": {
      duration: 0,
    },
  },
  layout: {
    "icon-image": "location",
    "icon-rotate": ["get", "bearing"],
    "icon-anchor": "bottom",
    "icon-offset": [0, 10],

    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
  filter: [
    "all",
    ["!=", ["get", "first"], true],
    ["!=", ["get", "last"], true],
  ],
};

const EVENT_END_LAYER: mapboxgl.SymbolLayer = {
  id: EVENT_END_LAYER_NAME,
  source: EVENT_SOURCE_NAME,
  type: "symbol",
  paint: {
    "icon-opacity-transition": {
      duration: 0,
    },
  },
  layout: {
    "icon-image": "end",
    "icon-anchor": "bottom",
    "icon-offset": [12, 10],

    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
  filter: ["all", ["==", ["get", "last"], true]],
};

const EVENT_START_LAYER: mapboxgl.SymbolLayer = {
  id: EVENT_START_LAYER_NAME,
  source: EVENT_SOURCE_NAME,
  type: "symbol",
  paint: {
    "icon-opacity-transition": {
      duration: 0,
    },
  },
  layout: {
    "icon-image": ["concat", "start-", ["get", "tid"]],
    "icon-anchor": "bottom",
    "icon-offset": [0, 10],

    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
  filter: ["all", ["==", ["get", "first"], true]],
};

const EVENT_DETAIL_LAYER: mapboxgl.SymbolLayer = {
  id: EVENT_DETAIL_LAYER_NAME,
  source: EVENT_SOURCE_NAME,
  type: "symbol",
  paint: {
    "icon-opacity-transition": {
      duration: 0,
    },
  },
  layout: {
    "icon-image": ["get", "icon"],
    "icon-anchor": "bottom",
    "icon-offset": [0, 8],

    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
  filter: [
    "all",
    ["!=", ["get", "first"], true],
    ["!=", ["get", "last"], true],
    // ["!=", ["get", "icon"], undefined],
  ],
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  map: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  textTransform: {
    textTransform: "none",
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  infoWindowDiv: {
    width: 300,
    maxWidth: 300,
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
  },
}));

interface MapboxGPSTrackingMapProps {
  psn?: string;
  app?: boolean;
  onDownload?: (data: ITrackData) => void;
  onPlay?: (data: ITrackData) => void;
  disableUpdate?: boolean;
}

export const MapboxGPSTrackingMap = ({
  psn,
  app,
  onDownload,
  onPlay,
  disableUpdate,
}: MapboxGPSTrackingMapProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const cancelRef = useRef<AbortController>();

  const themeState = useSelector((state: RootState) => state[THEME]);
  const { loadingLocation, color } = themeState;
  const { userSettings } = useSelector((state: RootState) => state[USER]);
  const {
    tracks,
    interval,
    selectedDrives,
    selectedDrive,
    selectedTrack,
    showTracks,
    updateLoc,
    firstFitBounds,
  } = useSelector((state: RootState) => state[GPS]);

  const { geofences, currentGeofence } = useSelector(
    (state: RootState) => state[GEOFENCE]
  );

  const rootDivRef = useRef<HTMLDivElement>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const infoMarkerRef = useRef<mapboxgl.Marker>();
  const isFirstDraw = useRef(true);
  const prevSelectedDrive = useRef<ITrackInfo>();
  const styleChangedRef = useRef(false);
  const orientation = useScreenOrientation();
  const windowSize = useWindowSize();

  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  const [fullscreen, setFullscreen] = useState(false);
  const [openLayer, setOpenLayer] = useState(false);

  const [mapInst, setMapInst] = useState<mapboxgl.Map>();

  const MAP_STYLE = useMemo(() => {
    const browser = detect();
    let style = DEFAULT_MAP_STYLE;
    if (browser?.name === "chrome" || browser?.name === "chromium-webview") {
      const vstring = browser?.version ?? "0.0";
      const version = parseInt(vstring.split(".")[0]);
      if (version <= 76) {
        style = NO_INT_MAP_STYLE;
      }
    }
    if (color === "dark") {
      style = DARKMODE_MAP_STYLE;
    }

    return style;
  }, [color]);

  useEffect(() => {
    if (!userSettings) dispatch(loadUserSettings());
  }, [dispatch, userSettings]);

  useEffect(() => {
    const timer = setTimeout(() => {
      map?.current?.resize();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [orientation, windowSize]);

  const loadTracks = useCallback(
    (psn: string, drive_no_list: number[], bounds?: mapboxgl.LngLatBounds) => {
      if (disableUpdate) return;

      const cancel = new AbortController();

      let googleBounds:
        | {
            east: number;
            west: number;
            south: number;
            north: number;
          }
        | undefined = undefined;
      if (bounds) {
        googleBounds = {
          east: bounds.getEast(),
          west: bounds.getWest(),
          south: bounds.getSouth(),
          north: bounds.getNorth(),
        };
      }

      dispatch(
        loadGPSTrackingData({
          psn: psn,
          drive_no_list,
          cancel,
          bounds: googleBounds,
        })
      );

      return cancel;
    },
    [disableUpdate, dispatch]
  );

  useEffect(() => {
    if (!map.current) return;
    if (!selectedTrack) return;
    // if (updateLoc === "map") return;

    // setClickedTrackId(feature.sid);
    const loc = selectedTrack.loc as [number, number];
    map.current?.jumpTo({
      center: loc,
    });

    const options: GPSTrackingInfoPanelOptions = {
      speedUnit: userSettings?.velocityUnit ?? "0",
      onDownload,
      onPlay,
    };

    // console.log(
    //   "MapboxGPSTrackingMap",
    //   "update selectedTrack",
    //   app,
    //   "selectedTrack",
    //   selectedTrack,
    //   "options",
    //   options
    // );
    const track: ITrackData = {
      ...selectedTrack,
      //@ts-ignore
      pevents:
        typeof selectedTrack.pevents === "string"
          ? selectedTrack.pevents
          : _.map(selectedTrack.pevents, (p) => ({
              ...p,
              vdate: p.vdate.unix(),
            })),
    };
    if (app) {
      infoMarkerRef.current = makeAppGPSTrackingInfoPanel(
        map.current,
        track,
        t,
        options
      );
    } else {
      infoMarkerRef.current = makeGPSTrackingInfoPanel(
        map.current,
        track,
        t,
        options
      );
    }

    return () => {
      if (infoMarkerRef.current) {
        infoMarkerRef.current.remove();
        infoMarkerRef.current = undefined;
      }
    };
  }, [
    app,
    onDownload,
    onPlay,
    selectedTrack,
    t,
    updateLoc,
    userSettings?.velocityUnit,
  ]);

  useEffect(() => {
    if (!showTracks) {
      isFirstDraw.current = true;
    }
  }, [showTracks]);

  useEffect(() => {
    if (psn && showTracks) {
      const drive_no_list = _.map(selectedDrives, (d) => d.drive_no);
      if (drive_no_list.length > 0) {
        let cancel: AbortController | undefined;
        cancel = loadTracks(
          psn,
          drive_no_list
          //   ignoreBounds.current ? undefined : bounds?.toJSON()
        );
        return () => {
          cancelRef.current?.abort();
          cancel?.abort();
        };
      }
    }
    // autoUpdate.current = false;
  }, [selectedDrives, loadTracks, psn, showTracks]);

  // // 아이콘 업데이트
  // useEffect(() => {
  //   if (!map.current) return;
  //   const drive_nos = _.map(selectedDrives, (drv) => drv.drive_no);

  // }, [selectedDrives, interval, selectedTrack?.sid, tracks, mapMode]);

  const updateSources = useCallback(() => {
    // console.log(
    //   "MapboxGPSTrackingMap",
    //   "!map.current",
    //   !map.current,
    //   "tracks.length",
    //   tracks.length,
    //   "selectedDrives.length",
    //   selectedDrives.length,
    //   tracks.length !== selectedDrives.length
    // );
    if (!map.current) return;
    // 앱화면에서 GPS Tracking 업데이트가 안되는 문제가 생겨서 주석처리
    if (tracks.length > 0 && tracks.length !== selectedDrives.length) return;
    const drive_nos = _.map(selectedDrives, (drv) => drv.drive_no);

    const lineSource = map.current.getSource(
      GPS_TRACKING_LINE_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;

    console.log(
      "MapboxGPSTrackingMap",
      "tracks",
      tracks,
      "selectedDrives",
      selectedDrives
    );

    if (lineSource) {
      const lines = _.chain(tracks)
        .filter((tr) => _.includes(drive_nos, tr.drive_no))
        .map((track) => {
          const connectedIndex: number[][] = [];
          const disconnectedIndex: number[][] = [];
          let prevIndex = 0;
          for (let i = 0; i < track.data.length - 1; i++) {
            if (track.data[i + 1].vdate.diff(track.data[i].vdate, "s") > 180) {
              disconnectedIndex.push([i, i + 1]);
              connectedIndex.push([prevIndex, i]);
              prevIndex = i + 1;
            }
          }
          if (prevIndex !== track.data.length - 1) {
            connectedIndex.push([0, track.data.length - 1]);
          }
          // if (connectedIndex.length === 0) {
          //   connectedIndex.push([0, track.data.length]);
          // }
          const features: any[] = [];
          for (let index of connectedIndex) {
            const coordinates = _.chain(track.data)
              .slice(index[0], index[1] + 1)
              .map((d) => d.loc)
              .value();
            if (coordinates.length === 1) {
              coordinates.push(coordinates[0]);
            }
            features.push(
              turf.feature(
                {
                  type: "LineString",
                  coordinates,
                },
                {
                  selected: selectedDrive?.drive_no === track.drive_no,
                  connected: true,
                  drive_no: track.drive_no,
                }
              )
            );
          }
          for (let index of disconnectedIndex) {
            features.push(
              turf.feature(
                {
                  type: "LineString",
                  coordinates: _.chain(track.data)
                    .slice(index[0], index[1] + 1)
                    .map((d) => d.loc)
                    .value(),
                },
                {
                  selected: selectedDrive?.drive_no === track.drive_no,
                  connected: false,
                  drive_no: track.drive_no,
                }
              )
            );
          }
          return features;
        })
        .flattenDeep()
        .value();

      const lineCollection = turf.featureCollection(
        lines
      ) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

      console.log(
        "MapboxGPSTrackingMap",
        "firstFitBounds",
        firstFitBounds,
        "lines.length",
        lines.length,
        "selectedDrive",
        selectedDrive,
        !_.isEqual(selectedDrive, prevSelectedDrive.current)
      );
      if (firstFitBounds) {
        if (lines.length > 0) {
          const bbox = turf.bbox(lineCollection) as mapboxgl.LngLatBoundsLike;
          console.log("MapboxGPSTrackingMap", "bbox", bbox);
          map.current?.fitBounds(bbox, {
            padding:
              app || mobile
                ? { top: 100, left: 100, right: 100, bottom: 100 }
                : { top: 100, left: 400, right: 100, bottom: 100 },
          });
          dispatch(setFirstFitBounds(false));
          dispatch(setSelectedDrive(tracks[0]));

          prevSelectedDrive.current = tracks[0];
        } else if (tracks?.[0]?.data?.length === 1) {
          const coordinates = _.chain(tracks[0].data)
            .map((d) => d.loc)
            .value();

          // console.log("MapboxGPSTrackingMap", "coordinates", coordinates);

          const lineCollection = turf.featureCollection([
            turf.feature({
              type: "LineString",
              coordinates,
            }),
          ]) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
          const bbox = turf.bbox(lineCollection) as mapboxgl.LngLatBoundsLike;
          map.current?.fitBounds(bbox, {
            padding: app
              ? { top: 50, left: 50, right: 50, bottom: 50 }
              : { top: 100, left: 400, right: 100, bottom: 100 },
            duration: 0,
            animate: false,
          });
          dispatch(setFirstFitBounds(false));
          dispatch(setSelectedDrive(tracks[0]));

          prevSelectedDrive.current = tracks[0];
        }
      } else {
        if (
          selectedDrive &&
          !_.isEqual(selectedDrive, prevSelectedDrive.current)
        ) {
          const coordinates = _.chain(selectedDrive.data)
            .map((d) => d.loc)
            .value();

          // console.log("MapboxGPSTrackingMap", "coordinates", coordinates);

          const lineCollection = turf.featureCollection([
            turf.feature({
              type: "LineString",
              coordinates,
            }),
          ]) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
          const bbox = turf.bbox(lineCollection) as mapboxgl.LngLatBoundsLike;
          map.current?.fitBounds(bbox, {
            padding: app
              ? { top: 50, left: 50, right: 50, bottom: 50 }
              : { top: 100, left: 400, right: 100, bottom: 100 },
            duration: 0,
            animate: false,
          });
        }
        prevSelectedDrive.current = selectedDrive;
      }

      // console.log("MapboxGPSTrackingMap", "featureCollections", lineCollection);

      lineSource.setData(lineCollection);
    }

    const eventSource = map.current.getSource(
      EVENT_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;

    if (eventSource) {
      const events = _.chain(tracks)
        .filter((tr) => _.includes(drive_nos, tr.drive_no))
        .map((track, tid) => {
          return _.chain(track.data)
            .filter((d) => {
              let show = true;
              if (interval === 2) {
                show = _.has(d, "t30s");
              }
              if (interval === 3) {
                show = _.has(d, "t1m");
              }
              if (interval === 4) {
                show = _.has(d, "t2m");
              }
              return show;
            })
            .map((data, i) => {
              const first = i === 0;
              const last = i === track.data.length - 1;
              let bearing = 0;
              let icon: string | undefined;
              if (data.mode === "M") {
                icon = "manual";
              } else if (data.mode === "P") {
                icon = "parking-normal";
                if (data.pevents && data.pevents.length > 0) {
                  icon = "parking-impact";
                }
              } else if (data.mode === "E" || data.mode === "J") {
                icon = "impact";
                if (_.includes([1, 3, 4, 5], data.mtype)) {
                  icon = "reckless";
                }
                if (data.mtype >= 6 && data.mtype <= 12) {
                  icon = "ai";
                }
                if (data.mtype >= 13 && data.mtype <= 16) {
                  icon = "geofence";
                }
                if (data.mtype === 17) {
                  icon = "ai";
                }
              }

              if (data.sid === selectedTrack?.sid) {
                icon = `${icon}-clicked`;
              }

              const point1 = turf.point(data.loc, {
                ...data,
                first,
                last,
                tid,
                icon,
                drive_no: track.drive_no,
                vdate: data.vdate.unix(),
                pevents: _.map(data.pevents, (p) => ({
                  ...p,
                  vdate: p.vdate.unix(),
                })),
              }) as any;
              if (!last) {
                const point2 = turf.point(track.data[i + 1]?.loc);
                bearing = turf.bearing(point1, point2);
              }

              point1.properties.bearing = bearing;

              return point1;
            })
            .value();
        })
        .flattenDeep()
        .value();
      // console.log(
      //   "MapboxGPSTrackingMap",
      //   "selectedTrack?.sid",
      //   selectedTrack?.sid
      //   // "events",
      //   // events
      // );

      const eventCollection = turf.featureCollection(events);

      // console.log(
      //   "MapboxGPSTrackingMap",
      //   "firstFitBounds",
      //   firstFitBounds,
      //   "events.length",
      //   events.length,
      //   "selectedDrive",
      //   selectedDrive,
      //   !_.isEqual(selectedDrive, prevSelectedDrive.current)
      // );
      // if (events.length > 0 && firstFitBounds) {
      //   const bbox = turf.bbox(eventCollection) as mapboxgl.LngLatBoundsLike;
      //   console.log("MapboxGPSTrackingMap", "bbox", bbox);
      //   map.current?.fitBounds(bbox, {
      //     padding:
      //       app || mobile
      //         ? { top: 100, left: 100, right: 100, bottom: 100 }
      //         : { top: 100, left: 400, right: 100, bottom: 100 },
      //   });
      //   dispatch(setFirstFitBounds(false));
      //   dispatch(setSelectedDrive(tracks[0]));

      //   prevSelectedDrive.current = tracks[0];
      // } else {
      //   if (
      //     selectedDrive &&
      //     !_.isEqual(selectedDrive, prevSelectedDrive.current)
      //   ) {
      //     const coordinates = _.chain(selectedDrive.data)
      //       .map((d) => d.loc)
      //       .value();

      //     // console.log("MapboxGPSTrackingMap", "coordinates", coordinates);

      //     const lineCollection = turf.featureCollection([
      //       turf.feature({
      //         type: "LineString",
      //         coordinates,
      //       }),
      //     ]) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
      //     const bbox = turf.bbox(lineCollection) as mapboxgl.LngLatBoundsLike;
      //     map.current?.fitBounds(bbox, {
      //       padding: app
      //         ? { top: 50, left: 50, right: 50, bottom: 50 }
      //         : { top: 100, left: 400, right: 100, bottom: 100 },
      //       duration: 0,
      //       animate: false,
      //     });
      //   }
      //   prevSelectedDrive.current = selectedDrive;
      // }

      eventSource.setData(eventCollection);
    }
  }, [
    app,
    dispatch,
    firstFitBounds,
    interval,
    mobile,
    selectedDrive,
    selectedDrives,
    selectedTrack?.sid,
    tracks,
  ]);

  // 라인 업데이트
  useEffect(() => {
    updateSources();
  }, [updateSources]);

  //줌 업데이트
  // useEffect(() => {
  //   console.log(
  //     "MapboxGPSTrackingMap",
  //     "selectedDrive",
  //     selectedDrive,
  //     prevSelectedDrive.current
  //   );
  //   if (selectedDrive && !_.isEqual(selectedDrive, prevSelectedDrive.current)) {
  //     const coordinates = _.chain(selectedDrive.data)
  //       .map((d) => d.loc)
  //       .value();

  //     // console.log("MapboxGPSTrackingMap", "coordinates", coordinates);

  //     const lineCollection = turf.featureCollection([
  //       turf.feature({
  //         type: "LineString",
  //         coordinates,
  //       }),
  //     ]) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  //     const bbox = turf.bbox(lineCollection) as mapboxgl.LngLatBoundsLike;
  //     map.current?.fitBounds(bbox, {
  //       padding: app
  //         ? { top: 50, left: 50, right: 50, bottom: 50 }
  //         : { top: 100, left: 400, right: 100, bottom: 100 },
  //       duration: 0,
  //       animate: false,
  //     });

  //     prevSelectedDrive.current = selectedDrive;
  //   }
  // }, [app, selectedDrive]);

  const initSourcesAndLayers = useCallback(() => {
    if (!map.current) return;

    // console.log(
    //   "MapboxGPSTrackingMap",
    //   "source",
    //   map.current.getSource(GPS_TRACKING_LINE_SOURCE_NAME)
    // );
    if (!map.current.getSource(GPS_TRACKING_LINE_SOURCE_NAME)) {
      map.current.addSource(GPS_TRACKING_LINE_SOURCE_NAME, {
        type: "geojson",
        data: turf.featureCollection([]),
      });
    }

    if (!map.current.getSource(EVENT_SOURCE_NAME)) {
      map.current.addSource(EVENT_SOURCE_NAME, {
        type: "geojson",
        data: turf.featureCollection([]),
      });
    }

    if (!map.current.getLayer(GPS_TRACKING_OUTLINE_LAYER_NAME)) {
      map.current.addLayer(GPS_TRACKING_OUTLINE_LAYER);
    }
    if (!map.current.getLayer(GPS_TRACKING_DISCONNECTED_OUTLINE_LAYER_NAME)) {
      map.current.addLayer(GPS_TRACKING_DISCONNECTED_OUTLINE_LAYER);
    }
    if (!map.current.getLayer(GPS_TRACKING_LINE_LAYER_NAME)) {
      map.current.addLayer(GPS_TRACKING_LINE_LAYER);
    }
    if (!map.current.getLayer(GPS_TRACKING_DISCONNECTED_LINE_LAYER_NAME)) {
      map.current.addLayer(GPS_TRACKING_DISCONNECTED_LINE_LAYER);
    }

    if (!map.current.getLayer(EVENT_DIR_LAYER_NAME)) {
      map.current.addLayer(EVENT_DIR_LAYER);
    }
    if (!map.current.getLayer(EVENT_END_LAYER_NAME)) {
      map.current.addLayer(EVENT_END_LAYER);
    }
    if (!map.current.getLayer(EVENT_START_LAYER_NAME)) {
      map.current.addLayer(EVENT_START_LAYER);
    }
    if (!map.current.getLayer(EVENT_DETAIL_LAYER_NAME)) {
      map.current.addLayer(EVENT_DETAIL_LAYER);
    }

    GPSTrackingIcons.forEach((icon) => {
      if (!icon.string) {
        let customIcon = new Image(icon.width, icon.height);
        customIcon.onload = () => {
          console.log("MapboxGPSTrackingMap", "icon onload", icon.name);
          map.current?.addImage(icon.name, customIcon);
        };
        customIcon.src = icon.src;
      }

      if (icon.string) {
        const canvas = document.createElement("canvas");
        canvas.width = icon.width;
        canvas.height = icon.height;
        const ctx = canvas.getContext("2d");
        const DOMURL = window.URL || window.webkitURL || window;

        const svg = new Blob([icon.src], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = DOMURL.createObjectURL(svg);
        const img = new Image();
        img.onload = function () {
          ctx?.drawImage(img, 0, 0, icon.width, icon.height);
          // DOMURL.revokeObjectURL(url);

          map.current?.addImage(icon.name, img);
        };

        img.src = url;
      }
    });
  }, []);

  useEffect(() => {
    if (!mapInst) return;

    updateGeofence(mapInst, geofences, "view", currentGeofence, true);
  }, [currentGeofence, geofences, mapInst]);

  useEffect(() => {
    if (!mapContainer.current) return;

    // console.log("MapboxGPSTrackingMap", "initmap", map.current);
    // initialize map only once
    if (map.current === null) {
      let options: mapboxgl.MapboxOptions = {
        container: mapContainer.current,
        style: MAP_STYLE,
        pitchWithRotate: false,
        dragRotate: false,
        touchPitch: false,
        center: DEFAULT_LOCATION,
        zoom: DEFAULT_ZOOM,
      };

      // console.log("MapboxGPSTrackingMap", "initmap - 2", options);
      map.current = new mapboxgl.Map(options);
    }

    if (map.current !== null) {
      const onLoad = async () => {
        // console.log("MapboxGPSTrackingMap", "onLoad");
        if (!map.current) return;
        map.current?.resize();

        initGeofenceSourcesAndLayers(map.current);
        initSourcesAndLayers();
        setMapInst(map.current);

        // setMapLoaded(true);
      };

      const onIdle = () => {
        // console.log("MapboxGPSTrackingMap", "onIdle", styleChangedRef.current);
        if (styleChangedRef.current) {
          styleChangedRef.current = false;
          initSourcesAndLayers();
          updateSources();
        }
      };

      const onMoveEnd = _.debounce(
        () => {
          // console.log(
          //   "MapboxGPSTrackingMap",
          //   "onMoveEnd",
          //   map.current?.getBounds()
          // );
          if (!map.current) return;
          if (isFirstDraw.current) {
            isFirstDraw.current = false;
            return;
          }

          const drive_no_list = _.map(selectedDrives, (d) => d.drive_no);
          if (drive_no_list.length > 0 && psn) {
            cancelRef.current?.abort();
            cancelRef.current = loadTracks(
              psn,
              drive_no_list,
              map.current.getBounds()
            );
          }
        },
        100,
        { trailing: true }
      );

      const onClickMap = async (e: any) => {
        if (!map.current) return;
        var features = map.current.queryRenderedFeatures(e.point, {
          layers: [
            EVENT_DIR_LAYER_NAME,
            EVENT_END_LAYER_NAME,
            EVENT_START_LAYER_NAME,
            EVENT_DETAIL_LAYER_NAME,
          ],
        });
        if (features.length > 0) {
          // console.log("MapboxGPSTrackingMap", "features", features);
          const feature = features[0].properties as ITrackData;
          if (feature) {
            const track = _.find(
              tracks,
              (tr) => tr.drive_no === feature.drive_no
            );
            dispatch(setSelectedDrive(track));
            dispatch(
              setSelectedTrack({
                updateLoc: "map",
                //@ts-ignore
                track: { ...feature, loc: JSON.parse(feature.loc) },
              })
            );
          }
        } else {
          dispatch(setSelectedTrack(undefined));
        }
      };

      const eventMouseEnter = () => {
        const canvas = map.current?.getCanvas();
        if (canvas) canvas.style.cursor = "pointer";
      };
      const eventMouseLeave = () => {
        const canvas = map.current?.getCanvas();
        if (canvas) canvas.style.cursor = "";
      };

      map.current?.on(
        "mouseenter",
        [
          EVENT_DIR_LAYER_NAME,
          EVENT_END_LAYER_NAME,
          EVENT_START_LAYER_NAME,
          EVENT_DETAIL_LAYER_NAME,
        ],
        eventMouseEnter
      );
      map.current?.on(
        "mouseleave",
        [
          EVENT_DIR_LAYER_NAME,
          EVENT_END_LAYER_NAME,
          EVENT_START_LAYER_NAME,
          EVENT_DETAIL_LAYER_NAME,
        ],
        eventMouseLeave
      );

      map.current.touchZoomRotate.disableRotation();
      map.current.on("load", onLoad);
      map.current.on("idle", onIdle);
      map.current.on("moveend", onMoveEnd);
      // map.current.on("click", EVENT_DIR_LAYER_NAME, onClickMap);
      // map.current.on("click", EVENT_END_LAYER_NAME, onClickMap);
      // map.current.on("click", EVENT_START_LAYER_NAME, onClickMap);
      // map.current.on("click", EVENT_DETAIL_LAYER_NAME, onClickMap);
      map.current.on("click", onClickMap);

      return () => {
        map.current?.off("load", onLoad);
        map.current?.off("idle", onIdle);
        map.current?.off("moveend", onMoveEnd);
        map.current?.off("click", GPS_TRACKING_LINE_LAYER_NAME, onClickMap);
        map.current?.off("mouseenter", EVENT_DIR_LAYER_NAME, eventMouseEnter);
        map.current?.off("mouseenter", EVENT_END_LAYER_NAME, eventMouseEnter);
        map.current?.off("mouseenter", EVENT_START_LAYER_NAME, eventMouseEnter);
        map.current?.off(
          "mouseenter",
          EVENT_DETAIL_LAYER_NAME,
          eventMouseEnter
        );
        map.current?.off("mouseleave", EVENT_DIR_LAYER_NAME, eventMouseLeave);
        map.current?.off("mouseleave", EVENT_END_LAYER_NAME, eventMouseLeave);
        map.current?.off("mouseleave", EVENT_START_LAYER_NAME, eventMouseLeave);
        map.current?.off(
          "mouseleave",
          EVENT_DETAIL_LAYER_NAME,
          eventMouseLeave
        );

        map.current?.off("click", onClickMap);
        // map.current?.off("click", EVENT_END_LAYER_NAME, onClickMap);
        // map.current?.off("click", EVENT_START_LAYER_NAME, onClickMap);
        // map.current?.off("click", EVENT_DETAIL_LAYER_NAME, onClickMap);
      };
    }
  }, [
    MAP_STYLE,
    dispatch,
    selectedDrives,
    loadTracks,
    onDownload,
    onPlay,
    psn,
    t,
    tracks,
    userSettings?.velocityUnit,
    initSourcesAndLayers,
    updateSources,
  ]);

  const iosBrowser = useMemo(() => {
    const browser = detect();
    return browser?.name === "ios" || browser?.name === "crios";
  }, []);

  const handleFullscreen = useCallback(() => {
    if (iosBrowser) {
      setFullscreen((f) => !f);
    } else {
      let elementToSendFullscreen: HTMLDivElement | null = rootDivRef.current;
      if (elementToSendFullscreen) {
        toggleFullscreen(elementToSendFullscreen);
        //@ts-ignore
        setFullscreen(elementToSendFullscreen.fullscreen);
        //@ts-ignore
        dispatch(setFullscreenTheme(elementToSendFullscreen.fullscreen));

        // if (app) {
        //   //@ts-ignore
        //   if (elementToSendFullscreen.fullscreen) {
        //     if (
        //       browserName === "ios-webview" &&
        //       //@ts-ignore
        //       webkit.messageHandlers.enableRotate
        //     ) {
        //       //@ts-ignore
        //       webkit.messageHandlers.enableRotate.postMessage({});
        //     }
        //     if (
        //       browserName === "chromium-webview" &&
        //       //@ts-ignore
        //       window.Webviewer?.enableRotate
        //     ) {
        //       //@ts-ignore
        //       window.Webviewer?.enableRotate?.();
        //     }
        //   } else {
        //     if (
        //       browserName === "ios-webview" &&
        //       //@ts-ignore
        //       webkit.messageHandlers.disableRotate
        //     ) {
        //       //@ts-ignore
        //       webkit.messageHandlers.disableRotate.postMessage({});
        //     }
        //     if (
        //       browserName === "chromium-webview" &&
        //       //@ts-ignore
        //       window.Webviewer?.disableRotate
        //     ) {
        //       //@ts-ignore
        //       window.Webviewer?.disableRotate?.();
        //     }
        //   }
        // }
      }
    }
  }, [dispatch, iosBrowser]);

  const mapboxMarkup = useMemo(() => {
    return <div ref={mapContainer} className={classes.map} />;
  }, [classes.map]);

  const mapTopRightMenuMarkup = useMemo(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          round
          layerRef={anchorRef}
          onFullscreen={app ? undefined : handleFullscreen}
          onLayers={() => setOpenLayer((o) => !o)}
          fullscreen={fullscreen}
          t={t}
          themeState={themeState}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{
              offset: {
                enabled: true,
                offset: "0, 4px",
              },
              preventOverflow: {
                enabled: false,
              },
            }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map.current?.setStyle(MAP_STYLE);
                styleChangedRef.current = true;
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map.current?.setStyle(SATELLITE_MAP_STYLE);
                styleChangedRef.current = true;
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu
            open={openLayer}
            onClose={() => setOpenLayer(false)}
            container={anchorRef.current}
            classes={{ root: classes.textTransform }}
          >
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map.current?.setStyle(MAP_STYLE);
                styleChangedRef.current = true;
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map.current?.setStyle(SATELLITE_MAP_STYLE);
                styleChangedRef.current = true;
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.textTransform,
    classes.mobileMemuItem,
    app,
    handleFullscreen,
    fullscreen,
    mobile,
    openLayer,
    mapMode,
    t,
    MAP_STYLE,
    themeState,
  ]);

  return (
    <div className={classes.root} ref={rootDivRef}>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      {mapboxMarkup}
      {mapTopRightMenuMarkup}
      <ZoomPanel
        onMyLocation={(position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.current?.setCenter(pos);
        }}
        onZoomIn={() => map.current?.zoomIn()}
        onZoomOut={() => map.current?.zoomOut()}
        hideLocation={true}
        loading={loadingLocation}
        onLoading={(v) => {
          dispatch(setLoadingLocation(v));
        }}
        t={t}
      />
    </div>
  );
};
