import React, { useState, useCallback } from "react";
import {
  Card,
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Selector, Switch, Typography } from "@thingsw/pitta-design-system";

import { SettingSlider } from "../SettingSlider";
import clsx from "clsx";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Webviewer,
  LightColors,
  ILiveEventSettings,
  TabNameInfo3,
  OnOffType,
  TrueFalsetype,
  BOXPRO_MODELS,
} from "@thingsw/pitta-modules";
import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: "0 0 7px 21px",
  },

  card: {
    maxWidth: 673,
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  switch: {
    marginRight: 19,
    height: 38,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  boderBottomBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },

  boderBottomNoneBox: {
    width: "100%",
    maxWidth: 672,
    // minHeight: 48,
    display: "flex",
    alignItems: "center",
    padding: "12px 20px 9px 24px",
  },

  boderNoneTwoRowBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "16px 20px 9px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 20px 9px 24px",
    },
  },

  boderNoneThirdPadding: {
    padding: "5px 20px 5px 24px",
  },

  hardAccelerationPadding: {
    padding: "13px 20px 3px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "13px 20px 6px 24px",
    },
  },

  thresholdPadding: {
    padding: "6px 19px 0px 19px",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "6px 20px 2px 20px",
      flexDirection: "row",
    },
  },

  liveEventUploadPadding: {
    padding: "8px 20px 9px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "9px 20px 9px 24px",
    },
  },

  beepAlertDiv: {
    padding: "5px 20px 4px 24px",
  },

  thresholdDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
      paddingRight: 27,
    },
  },

  thresholdInfo: {
    padding: "1px 19px 2px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "1px 20px 2px 20px",
    },
  },

  textColume: {
    display: "flex",
    flexDirection: "column",
  },

  cardDivider: {
    margin: "15px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },
  contentDivider: {
    margin: "8px 0px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "8px 20px 0px",
    },
  },
  sensitivityDiv: {
    margin: "12px 20px 0",
  },
  sliderOptionWrap: {
    display: "flex",
    padding: "10px 20px",
    justifyContent: "space-between",
  },
  marginR16: {
    marginRight: 16,
  },
  protectEventDiv: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 20px",
  },
  protectInfoText: {
    display: "flex",
    flexDirection: "column",
  },
  switchDiv: {
    display: "flex",
  },

  selectOptionWrap: {
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    padding: "16px 20px",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      alignItems: "center",
      flexDirection: "row",
      padding: "12px 20px",
    },
  },
  speedAlertDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "inherit",
    padding: "21px 20px 9px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      padding: "12px 20px 9px 24px",
    },
  },

  speedAlertSwitchDiv: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: 0,
    },
  },

  spaceBetween: {
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
    },
  },
  selectDiv: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },
  select: {
    borderRadius: 12,
    padding: "8px 16px",
  },
  selectPaper: {
    width: 234,
  },

  inputDiv: {
    minWidth: 303,
    marginTop: 20,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 267,
      width: 267,
      marginTop: 0,
    },
  },
  input: {
    borderRadius: 12,
    padding: "8px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8px 24px",
    },
  },
  sliderDiv: {
    width: "100%",
    marginRight: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
      marginRight: 0,
    },
  },
  sliderRoot: {
    padding: "16px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "20px 0",
    },
  },
  sliderInnerDiv: {
    paddingLeft: 11,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: 13,
    },
  },
  disabledText: {
    opacity: 0.35,
  },
  sliderRail: {
    color: LightColors.primary["1"],
  },
  trackRail: {
    color: LightColors.primary["3"],
    opacity: 0.38,
  },
}));

interface RecklessDrivingpanelProps {
  currentLiveEvent?: ILiveEventSettings;
  currentTab3Obj?: TabNameInfo3;
  onChangeTab3Setting: (key: keyof TabNameInfo3, val: string | boolean) => void;
  onLiveEventUpload: (key: keyof ILiveEventSettings, val: boolean) => void;
  handleResetLiveEvent: () => void;
  liveUploadLoading?: boolean;
  onDisabledSave: (val: boolean) => void;
}

export const RecklessDrivingpanel = ({
  currentLiveEvent,
  currentTab3Obj,
  onChangeTab3Setting,
  onLiveEventUpload,
  handleResetLiveEvent,
  liveUploadLoading,
  onDisabledSave,
}: RecklessDrivingpanelProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { t } = useTranslation();

  // const [acceleration, setAcceleration] = useState<boolean>(false);
  // const [braking, setBraking] = useState<boolean>(false);
  // const [cornering, setCornering] = useState<boolean>(false);
  const [inputError, setInputError] = useState<any>();

  const { camera } = useSelector((state: RootState) => state[CAMERA]);

  const isBoxPro = _.includes(BOXPRO_MODELS, camera?.model);

  const calcMPH = (unit: string, speed: string, defaultValue: string) => {
    if (speed === "0") {
      return defaultValue;
    }
    //mph
    if (unit === "1") {
      return speed;
    }
    // kmh
    return (parseFloat(speed) * 0.6213).toFixed(0);
  };

  const calcKMH = (unit: string, speed: string, defaultValue: string) => {
    if (speed === "0") {
      return defaultValue;
    }
    //mph
    if (unit === "1") {
      return (parseFloat(speed) * 1.6093).toFixed(0);
    }
    // kmh
    return speed;
  };

  const getBooleanOnOFF = (onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  };

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  const handleSpeedEventRev2 = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (currentTab3Obj) {
        const { value } = e.target;
        const regex = new RegExp(/(^[0][0-9])/gm);

        const test = regex.test(value);
        if (!test) {
          if (currentTab3Obj?.EventSpeedUnit === "1") {
            if (
              parseInt(e.target.value) <= 199 &&
              // mantis : 8716
              // Speed alert 체크 시 1부터 입력 가능하도록 수정
              parseInt(e.target.value) >= 1
            ) {
              onChangeTab3Setting("AlertLimit", value);
              if (inputError?.speed) setInputError({ speed: null});
              onDisabledSave(false);
              // mantis - 12651, AlertLimit값 ""로 저장가능한 이슈 수정 (Leehj)
              // mantis - 14058, Speed Alert 값 입력 방법 개선, error 메시지 추가 (Hongcs, 2024.07.25)
            } else if (e.target.value === "") {
              onChangeTab3Setting("AlertLimit", value);
              setInputError({ speed: t("Please enter a value.")});
              onDisabledSave(true);
              // onChangeTab3Setting("AlertLimit", "0");
              // if (currentLiveEvent?.NerOverSpeed === "on") {
              //   onLiveEventUpload("NerOverSpeed", false);
              // }
              // onChangeTab3Setting("SPEEDALERTVOICE", "0");
            }

            // 8772 - MPH를 선택한 경우 아래 실행문 실행되지 않도록 조건 추가
          } else if (currentTab3Obj?.EventSpeedUnit !== "1") {
            if (
              parseInt(e.target.value) <= 299 &&
              // mantis : 8716
              // Speed alert 체크 시 1부터 입력 가능하도록 수정
              parseInt(e.target.value) >= 1
            ) {
              // 8739 - 이벤트 고도화 1차 - 속도경고 설정값 변수 수정
              onChangeTab3Setting("AlertLimit", value);
              if (inputError?.speed) setInputError({ speed: null});
              onDisabledSave(false);
              // mantis - 12651, AlertLimit값 ""로 저장가능한 이슈 수정 (Leehj)
              // mantis - 14058, Speed Alert 값 입력 방법 개선, error 메시지 추가 (Hongcs, 2024.07.25)
            } else if (e.target.value === "") {
              onChangeTab3Setting("AlertLimit", value);
              setInputError({ speed: t("Please enter a value.")});
              onDisabledSave(true);
              // onChangeTab3Setting("AlertLimit", "0");
              // if (currentLiveEvent?.NerOverSpeed === "on") {
              //   onLiveEventUpload("NerOverSpeed", false);
              // }
              // onChangeTab3Setting("SPEEDALERTVOICE", "0");
            }
          }
        }
      }
    },
    [
      currentTab3Obj,
      onChangeTab3Setting,
      onDisabledSave,
      inputError,
      t,
    ]
  );

  return (
    <>
      <div className={classes.title}>
        <Typography category="Default" variant="SmallBold">
          {/* mantis - 11122, Reckless driving -> Reckless Driving로 문구 수정 (Leehj) */}
          {t("Reckless driving")}
        </Typography>
      </div>

      {/* Reckless driving unit card */}
      <Card className={classes.card}>
        <div className={clsx(classes.spaceBetween, classes.selectOptionWrap)}>
          <Typography category="Default" variant="Body">
            {t("Reckless driving unit")}
          </Typography>
          <div className={classes.selectDiv}>
            <Selector
              setting
              outlinedInputClassName={classes.select}
              menuItemClassName={classes.selectPaper}
              t={t}
              dense
              mobile={mobile}
              keyValue={currentTab3Obj?.EventSpeedUnit ?? "0"}
              onChange={(e) => {
                //live Event Upload Off
                handleResetLiveEvent();

                onChangeTab3Setting("EventSpeedUnit", e.key);
                onChangeTab3Setting("AlertLimit", e.key === "1" ? "62" : "100");
                onChangeTab3Setting("AccelLimit", e.key === "1" ? "7" : "11");
                onChangeTab3Setting("HarshLimit", e.key === "1" ? "8" : "13");
                onChangeTab3Setting("SharpLimit", e.key === "1" ? "9" : "15");

                // Speed Alert Off
                onChangeTab3Setting("AlertLimit", "0");

                //Beef Alert Off
                onChangeTab3Setting("SPEEDALERTVOICE", "0");
                onChangeTab3Setting("ACCELERATIONVOICE", "0");
                onChangeTab3Setting("HARSHBRAKINGVOICE", "0");
                onChangeTab3Setting("SHARPTURNVOICE", "0");

                // mantis 13851 - 단위 km/h 또는 MPH로 변환 시 DMS Distraction limit 값 연동 수정 (2024.05.23 hongcs)
                if (currentTab3Obj?.EventSpeedUnit !== e.key && currentTab3Obj?.DsmDistractedLimit) {
                  (e.key === "0")
                    ? onChangeTab3Setting("DsmDistractedLimit", (parseFloat(currentTab3Obj.DsmDistractedLimit) * 1.6093).toFixed(0)) // calcKMH 변환
                    : onChangeTab3Setting("DsmDistractedLimit", (parseFloat(currentTab3Obj.DsmDistractedLimit) * 0.6213).toFixed(0)) // calcMPH 변환
                }
              }}
              items={[
                {
                  key: "0",
                  value: "Km/h",
                },
                {
                  key: "1",
                  value: "MPH",
                },
              ]}
            />
          </div>
        </div>
      </Card>
      <Divider className={classes.cardDivider} />

      {/* Speed alert card */}
      <Card className={classes.card}>
        <div
          className={clsx(classes.boderBottomBox, classes.speedAlertDiv)}
        >
          <div className={classes.speedAlertSwitchDiv}>
            <div className={classes.switch}>
              <Switch
                // mantis - 10908, AlertLimit 0이 아니면 해당 항목 on되도록 수정 (Leehj)
                // checked={speedAlert}
                checked={currentTab3Obj?.AlertLimit !== "0"}
                onChange={(e) => {
                  if (!e.target.checked) {
                    onChangeTab3Setting("AlertLimit", "0");
                    if (currentLiveEvent?.NerOverSpeed === "on") {
                      onLiveEventUpload("NerOverSpeed", false);
                    }
                    onChangeTab3Setting("SPEEDALERTVOICE", "0");
                  } else {
                    onChangeTab3Setting(
                      "AlertLimit",
                      currentTab3Obj?.EventSpeedUnit === "1" ? "62" : "100"
                    );
                    // OFF -> ON 으로 변경될때는LiveEventUpload은 OFF로 노출 (Leehj) 23.01.10
                    if (currentLiveEvent?.NerOverSpeed === "on") {
                      onLiveEventUpload("NerOverSpeed", false);
                    }
                    // OFF -> ON 으로 변경될때는 Beep Alert은 기본값 OFF로 노출 (Leehj) 23.01.09
                    // onChangeTab3Setting("SPEEDALERTVOICE", "1");
                  }
                }}
              />
            </div>

            <Typography category="Default" variant="Body">
              {t("Speed alert_2")}
            </Typography>
          </div>

          <div className={classes.inputDiv}>
            <Input
              outlinedInputClassName={classes.input}
              dense
              setting
              placeholder={
                currentTab3Obj?.EventSpeedUnit === "1" ? "62" : "100 of 299"
              }
              defaultText={
                currentTab3Obj?.EventSpeedUnit === "1" ? "of 199" : "of 299"
              }
              disabled={currentTab3Obj?.AlertLimit === "0"}
              value={
                currentTab3Obj?.AlertLimit === "0"
                  ? currentTab3Obj?.EventSpeedUnit === "1"
                    ? "62"
                    : "100"
                  : currentTab3Obj?.AlertLimit
              }
              error={inputError?.speed ?? inputError?.speed}
              helperText={inputError?.speed ?? inputError?.speed}
              onChange={handleSpeedEventRev2}
            />
          </div>
        </div>

        <div className={classes.boderNoneTwoRowBox}>
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.AlertLimit === "0"}
              checked={getBooleanOnOFF(currentLiveEvent?.NerOverSpeed ?? "off")}
              onChange={(event) => {
                // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
                onLiveEventUpload("NerOverSpeed", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.AlertLimit === "0" && classes.disabledText
              )}
            >
              {t("Live Event Upload_2")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.AlertLimit === "0" && classes.disabledText
              )}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>

        <div
          className={clsx(
            classes.boderBottomNoneBox,
            classes.boderNoneThirdPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.AlertLimit === "0"}
              checked={getBoolean(
                currentTab3Obj?.SPEEDALERTVOICE ?? ("0" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("SPEEDALERTVOICE", e.target.checked);
              }}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.AlertLimit === "0" && classes.disabledText
            )}
          >
            {isBoxPro ? t("Voice Alert") : t("Beep Alert")}
          </Typography>
        </div>
      </Card>

      <Divider className={classes.cardDivider} />

      {/* Hard acceleration Event card */}
      <Card className={classes.card}>
        <div
          className={clsx(
            classes.boderBottomBox,
            classes.hardAccelerationPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // mantis - 10908, AccelLimit 0이 아니면 해당 항목 on되도록 수정 (Leehj)
              // checked={acceleration}
              checked={currentTab3Obj?.AccelLimit !== "0"}
              onChange={(e) => {
                // setAcceleration(e.target.checked);
                if (e.target.checked) {
                  onChangeTab3Setting(
                    "AccelLimit",
                    currentTab3Obj?.EventSpeedUnit === "1" ? "7" : "11"
                  );
                  // OFF -> ON 으로 변경될때는LiveEventUpload은 OFF로 노출 (Leehj) 23.01.10
                  if (currentLiveEvent?.NerAcceleration === "on") {
                    onLiveEventUpload("NerAcceleration", false);
                  }
                  // OFF -> ON 으로 변경될때는 Beep Alert은 기본값 OFF로 노출 (Leehj) 23.01.09
                  // onChangeTab3Setting("ACCELERATIONVOICE", "1");
                } else {
                  // mantis - 10908, 실제로 서버에는 AccelLimit 0으로 저장하고 ui만 11로 보이도록 (Leehj)
                  onChangeTab3Setting("AccelLimit", "0");
                  onChangeTab3Setting("ACCELERATIONVOICE", "0");
                  if (currentLiveEvent?.NerAcceleration === "on") {
                    onLiveEventUpload("NerAcceleration", false);
                  }
                }
              }}
            />
          </div>

          <Typography category="Default" variant="Body">
            {/* mantis - 11122, Hard acceleration Event -> Hard Acceleration로 문구 수정 (Leehj) */}
            {t("Hard acceleration Event")}
          </Typography>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdPadding)}
        >
          <div className={classes.thresholdDiv}>
            <Typography
              category="Default"
              variant="SmallBold"
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              // mantis - 10908, AccelLimit 0이면 비활성화 (Leehj)
              className={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.disabledText
              )}
            >
              {t("Threshold")}
            </Typography>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={
                currentTab3Obj?.AccelLimit !== "0"
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              // mantis - 10908, AccelLimit 0이면 비활성화 (Leehj)
              className={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.disabledText
              )}
            >
              {currentTab3Obj?.EventSpeedUnit === "0" 
                ? `${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.AccelLimit ?? "0",
                  "11"
                )}km/h (${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.AccelLimit ?? "0",
                  "7"
                )}MPH)`
                : `${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.AccelLimit ?? "0",
                  "7"
                )}MPH (${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.AccelLimit ?? "0",
                  "11"
                )}km/h)`
              }
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              rootClasses={classes.sliderRoot}
              railClasses={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.sliderRail
              )}
              trackClasses={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.trackRail
              )}
              sliderClasses={classes.sliderInnerDiv}
              min={currentTab3Obj?.EventSpeedUnit === "1" ? 6 : 10}
              max={currentTab3Obj?.EventSpeedUnit === "1" ? 37 : 60}
              value={parseInt(
                currentTab3Obj?.AccelLimit === undefined ||
                  currentTab3Obj?.AccelLimit === "0"
                  ? currentTab3Obj?.EventSpeedUnit === "1"
                    ? "7"
                    : "11"
                  : currentTab3Obj?.AccelLimit
              )}
              // mantis - 10908, AccelLimit 0이면 비활성화 (Leehj)
              disabled={currentTab3Obj?.AccelLimit === "0"}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab3Setting("AccelLimit", v.toString());
                }
              }}
            />
          </div>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdInfo)}
        >
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
            style={{ fontSize: mobile ? 12 : 11 }}
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.AccelLimit === "0" && classes.disabledText
            )}
          >
            {t("Triggers when the_")}
          </Typography>
        </div>
        <Divider className={classes.contentDivider} />
        <div
          className={clsx(
            classes.boderNoneTwoRowBox,
            classes.liveEventUploadPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // mantis - 10908, AccelLimit 0이면 비활성화 (Leehj)
              disabled={currentTab3Obj?.AccelLimit === "0"}
              checked={getBooleanOnOFF(
                currentLiveEvent?.NerAcceleration ?? "off"
              )}
              onChange={(event) => {
                // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
                onLiveEventUpload("NerAcceleration", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.disabledText
              )}
            >
              {t("Live Event Upload_2")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.AccelLimit === "0" && classes.disabledText
              )}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>

        <div className={clsx(classes.boderBottomNoneBox, classes.beepAlertDiv)}>
          <div className={classes.switch}>
            <Switch
              // mantis - 10908, AccelLimit 0이면 비활성화 (Leehj)
              disabled={currentTab3Obj?.AccelLimit === "0"}
              checked={getBoolean(
                currentTab3Obj?.ACCELERATIONVOICE ?? ("0" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("ACCELERATIONVOICE", e.target.checked);
              }}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.AccelLimit === "0" && classes.disabledText
            )}
          >
            {isBoxPro ? t("Voice Alert") : t("Beep Alert")}
          </Typography>
        </div>
      </Card>

      <Divider className={classes.cardDivider} />

      {/* Hard braking Event card */}
      <Card className={classes.card}>
        <div
          className={clsx(
            classes.boderBottomBox,
            classes.hardAccelerationPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // mantis - 10908, HarshLimit 0이 아니면 해당 항목 on되도록 수정 (Leehj)
              // checked={braking}
              checked={currentTab3Obj?.HarshLimit !== "0"}
              onChange={(e) => {
                // setBraking(e.target.checked);
                if (e.target.checked) {
                  onChangeTab3Setting(
                    "HarshLimit",
                    currentTab3Obj?.EventSpeedUnit === "1" ? "8" : "13"
                  );
                  // OFF -> ON 으로 변경될때는LiveEventUpload은 OFF로 노출 (Leehj) 23.01.10
                  if (currentLiveEvent?.NerHarshBraking === "on") {
                    onLiveEventUpload("NerHarshBraking", false);
                  }
                  // OFF -> ON 으로 변경될때는 Beep Alert은 기본값 OFF로 노출 (Leehj) 23.01.09
                  // onChangeTab3Setting("HARSHBRAKINGVOICE", "1");
                } else {
                  // mantis - 10908, 실제로 서버에는 HarshLimit 0으로 저장하고 ui만 13로 보이도록 (Leehj)
                  onChangeTab3Setting("HarshLimit", "0");
                  onChangeTab3Setting("HARSHBRAKINGVOICE", "0");
                  if (currentLiveEvent?.NerHarshBraking === "on") {
                    onLiveEventUpload("NerHarshBraking", false);
                  }
                }
              }}
            />
          </div>

          <Typography category="Default" variant="Body">
            {/* mantis - 11122, Hard braking Event -> Hard Braking로 문구 수정 (Leehj) */}
            {t("Hard braking Event")}
          </Typography>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdPadding)}
        >
          <div className={classes.thresholdDiv}>
            <Typography
              category="Default"
              variant="SmallBold"
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.disabledText
              )}
            >
              {t("Threshold")}
            </Typography>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={
                currentTab3Obj?.HarshLimit !== "0"
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.disabledText
              )}
            >
              {currentTab3Obj?.EventSpeedUnit === "0" 
                ? `${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.HarshLimit ?? "0",
                  "13"
                )}km/h (${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.HarshLimit ?? "0",
                  "8"
                )}MPH)`
                : `${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.HarshLimit ?? "0",
                  "8"
                )}MPH (${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.HarshLimit ?? "0",
                  "13"
                )}km/h)`
              }
            </Typography>
          </div>

          <div className={classes.sliderDiv}>
            <SettingSlider
              rootClasses={classes.sliderRoot}
              sliderClasses={classes.sliderInnerDiv}
              railClasses={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.sliderRail
              )}
              trackClasses={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.trackRail
              )}
              min={currentTab3Obj?.EventSpeedUnit === "1" ? 6 : 10}
              max={currentTab3Obj?.EventSpeedUnit === "1" ? 31 : 50}
              value={parseInt(
                currentTab3Obj?.HarshLimit === undefined ||
                  currentTab3Obj?.HarshLimit === "0"
                  ? currentTab3Obj?.EventSpeedUnit === "1"
                    ? "8"
                    : "13"
                  : currentTab3Obj.HarshLimit
              )}
              disabled={currentTab3Obj?.HarshLimit === "0"}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab3Setting("HarshLimit", v.toString());
                }
              }}
            />
          </div>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdInfo)}
        >
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
            style={{ fontSize: mobile ? 12 : 11 }}
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.HarshLimit === "0" && classes.disabledText
            )}
          >
            {t("Triggers when the_2")}
          </Typography>
        </div>
        <Divider className={classes.contentDivider} />
        <div
          className={clsx(
            classes.boderNoneTwoRowBox,
            classes.liveEventUploadPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.HarshLimit === "0"}
              checked={getBooleanOnOFF(
                currentLiveEvent?.NerHarshBraking ?? "off"
              )}
              onChange={(event) => {
                // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
                onLiveEventUpload("NerHarshBraking", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.disabledText
              )}
            >
              {t("Live Event Upload_2")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.HarshLimit === "0" && classes.disabledText
              )}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>

        <div className={clsx(classes.boderBottomNoneBox, classes.beepAlertDiv)}>
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.HarshLimit === "0"}
              checked={getBoolean(
                currentTab3Obj?.HARSHBRAKINGVOICE ?? ("0" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("HARSHBRAKINGVOICE", e.target.checked);
              }}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.HarshLimit === "0" && classes.disabledText
            )}
          >
            {isBoxPro ? t("Voice Alert") : t("Beep Alert")}
          </Typography>
        </div>
      </Card>
      <Divider className={classes.cardDivider} />

      {/* Hard cornering Event card */}
      <Card className={classes.card}>
        <div
          className={clsx(
            classes.boderBottomBox,
            classes.hardAccelerationPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // mantis - 10908, SharpLimit 0이 아니면 해당 항목 on되도록 수정 (Leehj)
              // checked={cornering}
              checked={currentTab3Obj?.SharpLimit !== "0"}
              onChange={(e) => {
                // setCornering(e.target.checked);
                if (e.target.checked) {
                  onChangeTab3Setting(
                    "SharpLimit",
                    currentTab3Obj?.EventSpeedUnit === "1" ? "9" : "15"
                  );
                  // OFF -> ON 으로 변경될때는LiveEventUpload은 OFF로 노출 (Leehj) 23.01.10
                  if (currentLiveEvent?.NerSharpTurn === "on") {
                    onLiveEventUpload("NerSharpTurn", false);
                  }
                  // OFF -> ON 으로 변경될때는 Beep Alert은 기본값 OFF로 노출 (Leehj) 23.01.09
                  // onChangeTab3Setting("SHARPTURNVOICE", "1");
                } else {
                  // mantis - 10908, 실제로 서버에는 SharpLimit 0으로 저장하고 ui만 15로 보이도록 (Leehj)
                  onChangeTab3Setting("SharpLimit", "0");
                  onChangeTab3Setting("SHARPTURNVOICE", "0");
                  if (currentLiveEvent?.NerSharpTurn === "on") {
                    onLiveEventUpload("NerSharpTurn", false);
                  }
                }
              }}
            />
          </div>

          <Typography category="Default" variant="Body">
            {/* mantis - 11122, Hard cornering Event -> Sharp turn로 문구 수정 (Leehj) */}
            {t("Hard Cornering Event")}
          </Typography>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdPadding)}
        >
          <div className={classes.thresholdDiv}>
            <Typography
              category="Default"
              variant="SmallBold"
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.disabledText
              )}
            >
              {t("Threshold")}
            </Typography>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={
                currentTab3Obj?.SharpLimit !== "0"
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              style={{ lineHeight: 1 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.disabledText
              )}
            >
              {currentTab3Obj?.EventSpeedUnit === "0" 
                ? `${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.SharpLimit ?? "0",
                  "15"
                )}km/h (${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.SharpLimit ?? "0",
                  "9"
                )}MPH)`
                : `${calcMPH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.SharpLimit ?? "0",
                  "9"
                )}MPH (${calcKMH(
                  currentTab3Obj?.EventSpeedUnit ?? "0",
                  currentTab3Obj?.SharpLimit ?? "0",
                  "15"
                )}km/h)`
              }
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              rootClasses={classes.sliderRoot}
              sliderClasses={classes.sliderInnerDiv}
              railClasses={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.sliderRail
              )}
              trackClasses={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.trackRail
              )}
              min={currentTab3Obj?.EventSpeedUnit === "1" ? 6 : 10}
              max={currentTab3Obj?.EventSpeedUnit === "1" ? 31 : 50}
              value={parseInt(
                currentTab3Obj?.SharpLimit === undefined ||
                  currentTab3Obj?.SharpLimit === "0"
                  ? currentTab3Obj?.EventSpeedUnit === "1"
                    ? "9"
                    : "15"
                  : currentTab3Obj?.SharpLimit
              )}
              disabled={currentTab3Obj?.SharpLimit === "0"}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab3Setting("SharpLimit", v.toString());
                }
              }}
            />
          </div>
        </div>

        <div
          className={clsx(classes.boderBottomNoneBox, classes.thresholdInfo)}
        >
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
            style={{ fontSize: mobile ? 12 : 11 }}
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.SharpLimit === "0" && classes.disabledText
            )}
          >
            {t("Triggers when more_")}
          </Typography>
        </div>
        <Divider className={classes.contentDivider} />
        <div
          className={clsx(
            classes.boderNoneTwoRowBox,
            classes.liveEventUploadPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.SharpLimit === "0"}
              checked={getBooleanOnOFF(currentLiveEvent?.NerSharpTurn ?? "off")}
              onChange={(event) => {
                // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
                onLiveEventUpload("NerSharpTurn", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.disabledText
              )}
            >
              {t("Live Event Upload_2")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(
                currentTab3Obj?.SharpLimit === "0" && classes.disabledText
              )}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>

        <div className={clsx(classes.boderBottomNoneBox, classes.beepAlertDiv)}>
          <div className={classes.switch}>
            <Switch
              disabled={currentTab3Obj?.SharpLimit === "0"}
              checked={getBoolean(
                currentTab3Obj?.SHARPTURNVOICE ?? ("0" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("SHARPTURNVOICE", e.target.checked);
              }}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(
              currentTab3Obj?.SharpLimit === "0" && classes.disabledText
            )}
          >
            {isBoxPro ? t("Voice Alert") : t("Beep Alert")}
          </Typography>
        </div>
      </Card>

      <Divider className={classes.cardDivider} />
    </>
  );
};
