import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  CheckBox,
  Fab,
  IconButton,
  Modal,
  Polygon,
  Sliders,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { CircularProgress, FormControlLabel, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary } from "../Accordion";
import {
  addGeofence,
  clearRedoList,
  clearUndoList,
  deleteGeofence,
  GEOFENCE,
  loadGeofences,
  popRedoList,
  popUndoList,
  requestRedrawing,
  setCurrentGeofence,
  setDrawingGeometry,
  setDrawingMode,
  setFillColor,
  setFillOpacity,
  setShowDetail,
  updateGeofence,
  setSpeedGeofences,
  saveSpeedPriority,
  setOpenAdjustPriority,
} from "../../features/Geofence/slice";
import { USER, loadUserSettings } from "../../features/User/slice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { EmptyItems } from "../EmptyItems";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import { GeofenceListItem } from "./GeofenceListItem";
import { CameraMenu } from "../cameras/CameraMenu";
import { MobileCameraMenu } from "../MobileCameraMenu";
import { NoResults } from "../NoResults";
import { AutoSizer } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";

import {
  FenceTypeCode,
  ICameraInfo,
  IGeofence,
  LightColors,
  PSN650,
  Webviewer,
  BOXPRO_MODELS,
} from "@thingsw/pitta-modules";
import { GeofenceCamerasSettingPopper, ICameraSettings } from "../GeofenceCamerasSettingPopper"
import { GeofenceBoxProSettingPopper, IBoxProSettings } from "../GeofenceBoxProSettingPopper"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      height: "calc(100% - 98px)",
    },
  },
  panelRootDiv: {
    /* Light mode/#FFFFFF ‧ 0 */
    background: LightColors.primary["0"],
    /* Shadow 2 ‧ Small UI elements */
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 12,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      transition: theme.transitions.create(["width", "height"]),
    },
  },
  panelRootDivOpen: {
    width: "100%",

    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
    },
  },
  rootClose: {
    width: 0,
  },
  panelDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    bottom: 0,
    transition: theme.transitions.create(["left", "right"]),
    outline: "none",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
    },
  },
  panelBodyDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    overflow: "auto",
  },
  mainPanelDivClose: {
    ...(theme.direction === "rtl" ? { right: "-100%" } : { left: "-100%" }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl" ? { right: -320 } : { left: -320 }),
    },
  },
  subPanelDivClose: {
    ...(theme.direction === "rtl" ? { right: "100%" } : { left: "100%" }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl" ? { right: 320 } : { left: 320 }),
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-start",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: "auto",
      justifyContent: "flex-end",
    },
  },
  adjustBtnDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
    marginBottom: 16,
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    padding: "15px 16px",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 56,
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
    zIndex: 100,
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  inputName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 84,
    padding: theme.spacing(3, 2, 1.875, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  expandIcon: {
    padding: 0,
  },
  coDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? {
        padding: theme.spacing(0, 6.5, 2, 2),
      }
      : {
        padding: theme.spacing(0, 2, 2, 6.5),
      }),
    "&:last-child": {
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  opacityDiv: {
    ...(theme.direction === "rtl"
      ? {
        paddingLeft: theme.spacing(2.625),
      }
      : {
        paddingRight: theme.spacing(2.625),
      }),
  },
  settingCheckBoxDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: -3,
    ...(theme.direction === "rtl"
      ? {
        padding: theme.spacing(0, 6, 1.5, 2),
      }
      : {
        padding: theme.spacing(0, 2, 1.5, 6),
      }),
    "&:last-child": {
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  speedInputWrap: {
    display: "flex",
    alignItems: "center",
    "& label": {
      marginBottom: 0,
    },
  },
  speedInput: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      padding: "6px 13px 6px 16px",
      borderRadius: 8,
    },
    "& input": {
      height: 16,
      width: 32,
    },
    "& > span": {
      marginLeft: 8,
    },
  },

  colorDiv: {
    marginBottom: 2,
  },
  menuTitle: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.5) }
      : { marginRight: theme.spacing(1.5) }),
    color: LightColors.primary["3"],
    fontSize: 18,
  },
  colorSelectDiv: {
    display: "flex",
    alignItems: "center",
  },
  colorSelected: {
    backgroundColor: LightColors.primary["7"],
  },
  colorSelector2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 28,
    height: 28,
    margin: theme.spacing(0.25, 0.5, 0.25, 0.25),
    borderRadius: "50%",
  },
  colorSelector1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: LightColors.primary["0"],
    borderRadius: "50%",
    width: 22,
    height: 22,
  },
  colorSelector: {
    width: 18,
    height: 18,
    borderRadius: 9,
    cursor: "pointer",
    margin: theme.spacing(0.25),
  },
  checkBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  checkBoxLabelText: {
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
  cameraListDiv: {
    padding: theme.spacing(1.625, 2, 1.625, 2.375),
  },
  camerasAccordionSummary: {
    padding: 0,
    minHeight: 36,
    "& .expendIcon": {
      color: "#68686E",
      transition: "all 300ms",
    },
    "& .expendIcon:hover": {
      color: "#0095E0",
    },
    "&.Mui-expanded": {
      minHeight: 36,
      "& .expendIcon": {
        transform: "rotate(180deg)",
      },
    },
  },
  cameraListTitleDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginBottom: 4,
  },
  notiSetDiv: {
    display: "flex",
    justifyContent: "end",
    marginTop: 8,
    "& button": {
      color: LightColors.primary["7"],
      fontSize: "0.875rem",
      padding: "4px 10px",
      minHeight: 28,
      "& .MuiButton-endIcon": {
        marginLeft: 2,
      },
    },
    "&.disabled button": {
      color: LightColors.primary["4"],
    },
  },
  notiSetErrDiv: {
    display: "flex",
    justifyContent: "end",
    marginRight: 16,
  },
  cameraListTitle: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.75),
  },
  cameraCheckBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75), marginLeft: theme.spacing(1.125) }
      : {
        marginLeft: theme.spacing(-0.75),
        marginRight: theme.spacing(1.125),
      }),
  },
  cameraDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? {
        padding: theme.spacing(0.75, 2.5, 0.375, 0.75),
      }
      : {
        padding: theme.spacing(0.75, 0.75, 0.375, 2.5),
      }),
  },
  cameraModelText: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3.375) }
      : { marginLeft: theme.spacing(3.375) }),
  },
  btnDiv: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    display: "flex",
    justifyContent: "flex-end",
    "&>button": {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(2) }
        : { marginLeft: theme.spacing(2) }),
    },
  },
  drawToolDiv: {
    display: "flex",
    backgroundColor: `${LightColors.primary["1"]}73`,
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(0.5) }
        : { marginLeft: theme.spacing(0.5) }),
    },
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      marginTop: 12,
    },
  },
  drawToolFab: {
    margin: theme.spacing(0.5),
  },
  disabledAddDiv: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: LightColors.primary["0"],
    opacity: 1 - 0.35,
  },
  disabledIcon: {
    opacity: 0.33,
  },
  polygonIcon: {
    "& path": {
      fill: "none",
      stroke: LightColors.primary["1"],
    },
    "&:hover  path": {
      fill: "none",
      stroke: LightColors.primary["7"],
    },
  },
  toolSelected: {
    backgroundColor: LightColors.primary["7"],

    "& path": {
      fill: LightColors.primary["0"],
    },
    "&:hover path": {
      fill: LightColors.primary["0"],
    },
    "&:hover,&:focus": {
      backgroundColor: LightColors.primary["7"],
    },
  },
  polygonSelected: {
    backgroundColor: LightColors.primary["7"],

    "& path": {
      fill: "none",
      stroke: LightColors.primary["0"],
    },
    "&:hover path": {
      fill: "none",
      stroke: LightColors.primary["0"],
    },
    "&:hover,&:focus": {
      backgroundColor: LightColors.primary["7"],
    },
  },
  disabledPolygonToolDiv: {
    "& path": {
      fill: "none",
      stroke: `${LightColors.primary["1"]}43`,
    },
  },
  disabledSpan: {
    width: 36,
    height: 36,
  },
  floatingBtnDiv: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: -40 } : { right: -40 }),

    zIndex: 99,
  },
  floatingDrawingDiv: {
    position: "absolute",
    top: -48,
    right: 0,
    left: 0,
    zIndex: 99,
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      top: 0,
      ...(theme.direction === "rtl"
        ? { right: "unset", left: -312 }
        : { left: "unset", right: -312 }),
    },
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  listDiv: {
    display: "flex",
    height: "100%",
  },
  moreIcon: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1) }
      : { marginLeft: theme.spacing(1) }),
    marginTop: -8,
  },
  camAllCheckDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  camAllCheckHide: {
    width: 0,
    overflow: "hidden",
  },
  pdT: {
    paddingTop: theme.spacing(2),
  },
  disabledText: {
    opacity: 0.35,
    cursor: "default",
  },

  accordionSummaryPadding: {
    padding: "8px 16px 8px 19px",
  },
  fabUXUI: {
    borderRadius: 12,
    border: `1px solid ${LightColors.primary["6"]}`,
    boxShadow: "none",
    marginTop: 20,
  },
  mgB10: {
    marginBottom: 10,
  },
  divider: {
    margin: theme.spacing(0, 3),
  },
}));

interface GeofencePanelProps {
  mode?: "view" | "add";
  disabledAdd?: boolean;
  disableRedo: boolean;
  disableUndo: boolean;
  mobile?: boolean;
  selectedFence?: IGeofence;
  onCancel: () => void;
  onSave: (data: { mode: "add" | "update"; geofence: IGeofence }) => void;
  onFocus: (focused: boolean) => void;
}

export const GeofencePanel = ({
  mode,
  disableRedo,
  disableUndo,
  mobile,
  onSave,
  onFocus,
}: GeofencePanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const cameraList = useSelector(
    (state: RootState) => state[CAMERA].cameraList
  );

  const {
    loading,
    type,
    geofences: origGeofences,
    speedGeofences,
    currentGeofence,
    drawingMode,
    drawingGeometry,
    fillColor,
    fillOpacity,
    showDetail,
    openAdjustPriority,
  } = useSelector((state: RootState) => state[GEOFENCE]);

  const { userSettings } = useSelector((state: RootState) => state[USER]);

  const bodyRef = useRef<any>(null);
  const viewMoreBtnRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<List>(null);

  const [listDiv, setListDiv] = useState<HTMLDivElement | null>(null);
  const [
    moreBtnRef,
    setMoreBtnRef,
  ] = useState<React.RefObject<HTMLButtonElement> | null>(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openGeofenceSearch, setGeofenceOpenSearch] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openBoxProSearch, setOpenBoxProSearch] = useState(false);
  const [openPanel, setOpenPanel] = useState(true);
  const [openColorPanel, setOpenColorPanel] = useState(true);

  const [openMenu, setOpenMenu] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState<ICameraInfo[]>([]);
  const [selectedBoxProCamera, setSelectedBoxProCamera] = useState<ICameraInfo[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [indetermBoxPros, setIndetermBoxPros] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [allCheckedBoxPros, setAllCheckedBoxPros] = useState(false);

  const [name, setName] = useState("");
  const [moreZone, setMoreZone] = useState<IGeofence>();
  const [, /*currentZone*/ setCurrentZone] = useState<IGeofence>();
  const [searchKey, setSearchKey] = useState("");
  const [searchBoxProKey, setSearchBoxProKey] = useState("");
  const [geofenceSearchKey, setGeofenceSearchKey] = useState("");
  const [cameras, setCameras] = useState<ICameraInfo[]>([]);
  const [boxProCameras, setBoxProCameras] = useState<ICameraInfo[]>([]);
  const [geofences, setGeofences] = useState<IGeofence[]>([]);
  const [unallowed, setUnallowed] = useState(false);

  const [openCameraList, setOpenCameraList] = useState<boolean>(true);
  const [openBoxProList, setOpenBoxProList] = useState<boolean>(true);
  const [openCamerasSettingsPopper, setOpenCamerasSettingsPopper] = useState<boolean>(false);
  const [openBoxProSettingsPopper, setOpenBoxProSettingsPopper] = useState<boolean>(false);
  const [cameraSettings, setCameraSettings] = useState<ICameraSettings>();
  const [boxProSettings, setBoxProSettings] = useState<IBoxProSettings>();
  const geofencPanelRef = useRef<HTMLDivElement>(null);
  const [adjustablePriority, setAdjustablePriority] = useState<boolean>(false);

  useEffect(() => {
    dispatch(loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      const key = e.key || e.keyCode;
      switch (key) {
        case "Delete":
          setOpenDelete(true);
          break;
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  const handleDelete = () => {
    reset();
    dispatch(requestRedrawing());
  };

  const disabledAdd = useMemo(() => {
    return !currentGeofence && !drawingGeometry;
  }, [currentGeofence, drawingGeometry]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    if (mobile) {
      const body = document.getElementById("body-container");
      body?.setAttribute("style", "overflow:hidden;");

      return () => {
        body?.setAttribute("style", "overflow:auto;");
      };
    }
  }, [mobile]);

  useEffect(() => {
    setGeofences(
      _.filter(origGeofences, (geo) =>
        geofenceSearchKey
          ? geo.fenceName
            .toLowerCase()
            .indexOf(geofenceSearchKey.toLowerCase()) > -1
          : true
      )
    );
  }, [geofenceSearchKey, origGeofences]);

  useEffect(() => {
    if (cameraList?.deviceListInfo) {
      setCameras(
        _.chain(cameraList.deviceListInfo)
          .filter((dev) => dev.device.reg_category === "cloud")
          .filter((dev) => !_.includes(BOXPRO_MODELS, dev.device.model))
          .filter((dev) =>
            searchKey
              ? dev.device.dev_name
                .toLowerCase()
                .indexOf(searchKey.toLowerCase()) > -1 ||
              dev.device.model
                .toLowerCase()
                .indexOf(searchKey.toLowerCase()) > -1
              : true
          )
          .map((dev) => dev.device)
          .value()
      );
      setBoxProCameras(
        _.chain(cameraList.deviceListInfo)
          .filter((dev) => dev.device.reg_category === "cloud")
          .filter((dev) => _.includes(BOXPRO_MODELS, dev.device.model))
          .filter((dev) =>
            searchBoxProKey
              ? dev.device.dev_name
                .toLowerCase()
                .indexOf(searchBoxProKey.toLowerCase()) > -1 ||
              dev.device.model
                .toLowerCase()
                .indexOf(searchBoxProKey.toLowerCase()) > -1
              : true
          )
          .map((dev) => dev.device)
          .value()
      );
    }
  }, [cameraList, searchKey, searchBoxProKey]);

  const reset = useCallback(() => {
    setCameraSettings(undefined);
    setBoxProSettings(undefined);
    setSelectedCamera([]);
    setSelectedBoxProCamera([]);
    setName("");
    dispatch(setFillOpacity(0.5));
    // onCancel();
    dispatch(setShowDetail(false));
    setSearchKey("");
    setSearchBoxProKey("");
    setOpenSearch(false);
    setOpenBoxProSearch(false);
    dispatch(setCurrentGeofence(undefined));
    dispatch(setDrawingGeometry(undefined));
    dispatch(clearUndoList());
    dispatch(clearRedoList());
  }, [dispatch]);

  useEffect(() => {
    if (!currentGeofence) return;
    // console.log("currentGeofence::", currentGeofence);
    const fence = currentGeofence;
    // 770XBox Pro SpeedAlert 추가 시 alarm 데이터 삭제
    // setSettings({...settings, checkEnter: _.includes(fence.alarm, "Enter")});
    // setSettings({...settings, checkExit: _.includes(fence.alarm, "Exit")});
    // setSettings({...settings, checkPass: _.includes(fence.alarm, "Pass")});
    // setSettings({...settings, checkSpeed: _.includes(fence.alarm, "Speed")});   
    setCameraSettings({
      checkLiveEventEnter: _.includes(fence.liveEventUpload, "Enter"),
      checkLiveEventExit: _.includes(fence.liveEventUpload, "Exit"),
      checkLiveEventPass: _.includes(fence.liveEventUpload, "Pass"),
    });
    setSelectedCamera(
      _.filter(cameras, (dev) => _.includes(fence.psn, dev.psn))
    );
    dispatch(setFillColor(fence.setShape.Color));
    setName(fence.fenceName);
    dispatch(setFillOpacity(fence.setShape.Transparency));

    // Box Pro 세팅 추가
    setSelectedBoxProCamera(
      _.filter(boxProCameras, (dev) => _.includes(fence.newPsn, dev.psn))
    );
    setBoxProSettings({
      checkEnterSdCard: fence.newRecording?.enterSdCard === "on" ? true : false,
      checkEnterSdCardLiveEvent: fence.newRecording?.enterLiveEventUpload === "on" ? true : false,
      checkExitSdCard: fence.newRecording?.exitSdCard === "on" ? true : false,
      checkExitSdCardLiveEvent: fence.newRecording?.exitLiveEventUpload === "on" ? true : false,
      checkPassSdCard: fence.newRecording?.passSdCard === "on" ? true : false,
      checkPassLiveEventUpload: fence.newRecording?.passLiveEventUpload === "on" ? true : false,
      checkSpeedLiveEvent: fence.newRecording?.speedLiveEventUpload === "on" ? true : false,
      checkSpeed: fence.speed !== undefined,
      alarmSpeed: fence.speed
        ? (userSettings?.velocityUnit === "0")
          ? String(fence.speed?.Km) ?? ""
          : String(fence.speed?.Mph) ?? ""
        : ""
    });
  }, [
    cameras,
    boxProCameras,
    currentGeofence,
    dispatch,
    userSettings?.velocityUnit,
  ]);

  const initGeofence = useCallback(
    (fence: IGeofence) => {
      // setCurrentZone(fence);
      dispatch(setCurrentGeofence(fence));
      dispatch(setShowDetail(true));
      // dispatch(setDrawingMode(FenceTypeName[fence.fenceType]));
    },
    [dispatch]
  );

  useEffect(() => {
    if (mode === "add") {
      dispatch(setShowDetail(true));
      setOpenPanel(true);
    }
  }, [dispatch, mode]);

  // useEffect(() => {
  //   if (selectedFence) {
  //     initGeofence(selectedFence);
  //     dispatch(setShowDetail(true));
  //     setOpenPanel(true);
  //   }
  // }, [dispatch, initGeofence, selectedFence]);

  useEffect(() => {
    if (mode === "add") {
      setCurrentZone(undefined);
      reset();
    }
  }, [mode, reset]);

  useEffect(() => {
    if (!loading && type === deleteGeofence.type) {
      setOpenDeleteModal(false);
      reset();
    }
    if (!loading && type === updateGeofence.type) {
      reset();
    }
  }, [reset, loading, type]);

  useEffect(() => {
    dispatch(loadGeofences());
  }, [dispatch]);

  useEffect(() => {
    if (type === addGeofence.type && !loading) {
      reset();
    }
  }, [reset, loading, type]);

  useEffect(() => {
    setIndeterminate(
      selectedCamera.length > 0 && cameras.length !== selectedCamera.length
    );
    setAllChecked(cameras.length === selectedCamera.length);
  }, [cameras.length, selectedCamera]);

  useEffect(() => {
    setIndetermBoxPros(
      selectedBoxProCamera.length > 0 && boxProCameras.length !== selectedBoxProCamera.length
    );
    setAllCheckedBoxPros(boxProCameras.length === selectedBoxProCamera.length);
  }, [boxProCameras.length, selectedBoxProCamera]);

  useEffect(() => {
    if (!geofences) return;
    setAdjustablePriority(_.filter(geofences, (geo) => geo.speed !== undefined).length > 1)
  }, [geofences]);

  const adjustPriority = useCallback((fence: IGeofence, type: "up" | "down") => {
    const fences = _.map(speedGeofences, (p: any) => {
      if (
        !fence?.priority
        || (type === "up" && fence?.priority === 1)
        || (type === "down" && fence?.priority === speedGeofences.length)
      ) return p;
      if (p.fenceId === fence.fenceId) {
        return {
          ...p,
          fenceId: p.fenceId,
          priority: type === "up" ? p.priority - 1 : p.priority + 1,
        };
      } else if (type === "up" && p.priority === fence?.priority - 1) {
        return {
          ...p,
          fenceId: p.fenceId,
          priority: p.priority + 1,
        };
      } else if (type === "down" && p.priority === fence?.priority + 1) {
        return {
          ...p,
          fenceId: p.fenceId,
          priority: p.priority - 1,
        };
      } else {
        return {
          ...p,
          fenceId: p.fenceId,
          priority: p.priority,
        };
      }
    })
      .sort((a, b) => a.priority - b.priority);
    // console.log(fences);
    dispatch(setSpeedGeofences(fences));
  }, [speedGeofences, dispatch]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const { index, style, key } = props;
      const fence = !openAdjustPriority ? geofences[index] : speedGeofences[index]
      return (
        <div style={{ ...style }} key={key}>
          <GeofenceListItem
            geofence={fence}
            onClick={() => {
              initGeofence(fence);
            }}
            onClickMore={(ref) => {
              setMoreBtnRef(ref);
              setOpenMenu((o) => !o);
              setMoreZone(fence);
            }}
            velocityUnit={userSettings?.velocityUnit}
            openAdjustPriority={openAdjustPriority}
            adjustPriority={(fence: IGeofence, type: "up" | "down") => adjustPriority(fence, type)}
            geofences={!openAdjustPriority ? geofences : speedGeofences}
          />
        </div>
      );
    },
    [
      geofences,
      speedGeofences,
      initGeofence,
      openAdjustPriority,
      userSettings?.velocityUnit,
      adjustPriority,
    ]
  );

  const cameraListMarkup = useCallback((
    camList?: ICameraInfo[],
    box_pro?: boolean,
  ) => {
    return (
      <div className={classes.cameraListDiv}>
        <Accordion
          elevation={0}
          expanded={box_pro ? openBoxProList : openCameraList}
          onChange={() => box_pro ? setOpenBoxProList((o) => !o) : setOpenCameraList((o) => !o)}
        >
          <div className={classes.cameraListTitleDiv}>
            <AccordionSummary
              className={clsx(classes.camerasAccordionSummary, {
                [classes.camAllCheckDiv]: box_pro ? !openBoxProSearch : !openSearch,
                [classes.camAllCheckHide]: box_pro ? openBoxProSearch : openSearch,
              })}
            >
              <ArrowDropUpIcon className="expendIcon" />
              <Typography
                category="Default"
                variant="Body"
                className={classes.cameraListTitle}
              >
                {box_pro ? t("Box Pro Models") : t("Cameras")}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
              >
                {box_pro ? selectedBoxProCamera.length : selectedCamera.length}
                /
                {box_pro ? boxProCameras.length : cameras.length}
              </Typography>
            </AccordionSummary>
            <div
              className={clsx({
                [classes.camAllCheckDiv]: box_pro ? openBoxProSearch : openSearch,
              })}
            >
              <Input
                SecondaryColor
                placeholder={t("search")}
                startIcon={
                  <Tooltip
                    disableTouchListener={mobile}
                    placement="top"
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "0, -12px",
                        },
                        flip: {
                          enabled: false,
                        },
                      },
                    }}
                    title={
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("search")}
                      </Typography>
                    }
                  >
                    <SearchIcon />
                  </Tooltip>
                }
                onChange={(e) => box_pro ? setSearchBoxProKey(e.target.value) : setSearchKey(e.target.value)}
                value={box_pro ? searchBoxProKey : searchKey}
                variant="standard"
                search
                dense
                onOpenSearch={() => {
                  if (box_pro) {
                    setOpenBoxProSearch(true);
                    if (!openBoxProList) setOpenBoxProList(true);
                  } else {
                    setOpenSearch(true);
                    if (!openCameraList) setOpenCameraList(true);
                  }
                }}
                onCloseSearch={() => {
                  if (box_pro) {
                    setSearchBoxProKey("");
                    setOpenBoxProSearch(false);
                  } else {
                    setSearchKey("");
                    setOpenSearch(false);
                  }
                }}
              />
            </div>
          </div>
          <AccordionDetails>
            {(!camList || (camList.length === 0 && searchKey)) ? (
              <NoResults />
            ) : (
              <>
                {camList.length > 1 && (
                  <div className={classes.cameraDiv}>
                    <FormControlLabel
                      className={classes.cameraCheckBoxLabel}
                      control={
                        <CheckBox
                          color="primary"
                          indeterminate={box_pro ? indetermBoxPros : indeterminate}
                          checked={box_pro ? allCheckedBoxPros : allChecked}
                          onChange={(e, checked) => {
                            if (!box_pro) {
                              if (selectedCamera.length === 0) {
                                setSelectedCamera(
                                  _.filter(
                                    cameras,
                                    (dev) =>
                                      !_.includes(PSN650, dev.psn.substr(0, 4))
                                  )
                                );
                              } else {
                                setSelectedCamera([])
                              }
                            } else {
                              selectedBoxProCamera.length === 0
                                ?
                                setSelectedBoxProCamera(boxProCameras)
                                :
                                setSelectedBoxProCamera([])
                            }
                          }}
                        />
                      }
                      label={
                        <Typography
                          category="Default"
                          variant="Body"
                        >
                          {t("Select all")}
                        </Typography>
                      }
                    />
                  </div>
                )}
                {_.map(camList, (dev) => (
                  <div key={dev.psn} className={classes.cameraDiv}>
                    <FormControlLabel
                      className={classes.cameraCheckBoxLabel}
                      control={
                        <CheckBox
                          disabled={_.includes(PSN650, dev.psn.substr(0, 4))}
                          color="primary"
                          checked={
                            box_pro
                              ?
                              _.includes(
                                _.map(selectedBoxProCamera, (c) => c.psn),
                                dev.psn
                              )
                              :
                              _.includes(
                                _.map(selectedCamera, (c) => c.psn),
                                dev.psn
                              )
                          }
                          onChange={(e, checked) => {
                            if (checked) {
                              box_pro ? setSelectedBoxProCamera((c) => [...c, dev]) : setSelectedCamera((c) => [...c, dev])
                            } else {
                              box_pro
                                ?
                                setSelectedBoxProCamera((cams) =>
                                  _.filter(cams, (c) => c.psn !== dev.psn)
                                )
                                :
                                setSelectedCamera((cams) =>
                                  _.filter(cams, (c) => c.psn !== dev.psn)
                                )
                            }
                          }}
                        />
                      }
                      label={
                        <Typography
                          category="Default"
                          variant="Body"
                          style={{ overflowWrap: "anywhere" }}
                          className={classes.checkBoxLabelText}
                        >
                          {dev.dev_name}
                        </Typography>
                      }
                    />
                    <Typography
                      category="Default"
                      variant="Small"
                      className={clsx(
                        classes.cameraModelText,
                        _.includes(PSN650, dev.psn.substr(0, 4)) && classes.disabledText
                      )}
                      htmlColor={LightColors.primary["2"]}
                    >
                      {dev.model}
                    </Typography>
                  </div>
                ))}
                <div className={clsx(classes.notiSetDiv, {
                  "disabled": box_pro ? !selectedBoxProCamera.length : !selectedCamera.length,
                })}>
                  <Button
                    variant="text"
                    color="gray"
                    endIcon={<ChevronRightIcon />}
                    disabled={box_pro ? !selectedBoxProCamera.length : !selectedCamera.length}
                    onClick={() => {
                      box_pro ? setOpenBoxProSettingsPopper(true) : setOpenCamerasSettingsPopper(true)
                    }}
                    // mantis - 14181, 설정값 선택되지 않은 상태의 에러메시지 제거 (Hongcs, 24.07.30)
                    // startIcon={
                    //   ((box_pro && !_.some(boxProSettings, s => s === true) && selectedBoxProCamera.length > 0) ||
                    //     (!box_pro && !_.some(cameraSettings, s => s === true) && selectedCamera.length > 0)) && (
                    //     <div
                    //       style={{
                    //         width: 4,
                    //         height: 4,
                    //         borderRadius: 2,
                    //         backgroundColor: LightColors.secondary["11"],
                    //       }}
                    //     />
                    //   )
                    // }
                  >
                    {t("Cameras settings")}
                  </Button>
                </div>
                {/* mantis - 14181, 설정값 선택되지 않은 상태의 에러메시지 제거 (Hongcs, 24.07.30) */}
                {/* {(
                  (box_pro && !_.some(boxProSettings, s => s === true) && selectedBoxProCamera.length > 0) ||
                  (!box_pro && !_.some(cameraSettings, s => s === true) && selectedCamera.length > 0)) && (
                    <div className={classes.notiSetErrDiv}>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.secondary["11"]}
                      >
                        {t("Please configure the settings")}
                      </Typography>
                    </div>
                  )} */}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }, [
    classes,
    searchKey,
    selectedCamera,
    selectedBoxProCamera,
    allChecked,
    allCheckedBoxPros,
    cameras,
    boxProCameras,
    openSearch,
    openBoxProSearch,
    searchBoxProKey,
    indeterminate,
    indetermBoxPros,
    openCameraList,
    openBoxProList,
    mobile,
    t,
  ]);

  useEffect(() => {
    console.log("clientHeight", listDiv?.clientHeight);
    listRef.current?.forceUpdateGrid();
  }, [listDiv?.clientHeight]);

  const handleScroll = useCallback((e) => {
    if (listRef.current) {
      const { scrollTop, scrollLeft } = e.target;
      const { Grid } = listRef.current;
      Grid?.handleScrollEvent({ scrollTop, scrollLeft });
    }
  }, []);

  const geofenceListMarkup = useMemo(() => {
    if (loading && type === loadGeofences.type) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    }

    if (geofences.length === 0) {
      if (geofenceSearchKey) {
        return (
          <div className={classes.loadingDiv}>
            <NoResults />
          </div>
        );
      } else {
        return (
          <div className={classes.loadingDiv}>
            <EmptyItems mode="geofences" variant="small" />
          </div>
        );
      }
    }

    return (
      <div className={classes.listDiv} ref={(ref) => setListDiv(ref)}>
        <AutoSizer>
          {({ height, width }) => (
            <Scrollbars
              onScroll={handleScroll}
              style={{ height, width }}
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{
                    marginLeft: theme.direction === "rtl" ? -17 : 0,
                    marginRight: theme.direction === "rtl" ? 0 : -17,
                    overflowY: "auto",
                  }}
                />
              )}
            >
              <List
                ref={listRef}
                width={width}
                height={height}
                rowCount={openAdjustPriority ? speedGeofences.length : geofences.length}
                rowHeight={60}
                // overscanRowCount={5}
                rowRenderer={rowRenderer}
                style={
                  mobile ? {} : { overflowY: "visible", overflowX: "visible" }
                }
              />
            </Scrollbars>
          )}
        </AutoSizer>
      </div>
    );
  }, [
    classes.listDiv,
    classes.loadingDiv,
    geofenceSearchKey,
    geofences.length,
    handleScroll,
    loading,
    mobile,
    rowRenderer,
    theme.direction,
    type,
    openAdjustPriority,
    speedGeofences.length,
  ]);

  const onSpeedPriority = useCallback(() => {
    dispatch(loadGeofences({ priority: true }));
    setOpenMenu(false);
  }, [dispatch]);

  return (
    <div className={clsx(classes.root)} dir={theme.direction}>
      <div
        className={clsx(classes.panelRootDiv, {
          [classes.panelRootDivOpen]: openPanel,
          [classes.rootClose]: !openPanel,
        })}
      >
        <div
          ref={geofencPanelRef}
          className={clsx(classes.panelDiv, {
            [classes.mainPanelDivClose]: mode === "add" || showDetail,
          })}
        >
          <div className={clsx(classes.titleDiv)}>
            {!openGeofenceSearch && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <Typography category="Default" variant="H6">
                  {openAdjustPriority ? t("Speed Priorities") : t("Geofences")}
                </Typography>
              </div>
            )}
            {!openAdjustPriority && (
              <Input
                inputID="geofencesSearch"
                placeholder={t("Search geofences")}
                startIcon={
                  <Tooltip
                    disableTouchListener={mobile}
                    placement="top"
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "0, -12px",
                        },
                        flip: {
                          enabled: false,
                        },
                      },
                    }}
                    title={
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("search")}
                      </Typography>
                    }
                  >
                    <SearchIcon />
                  </Tooltip>
                }
                className={clsx(classes.searchBtn, {
                  [classes.searchBtnOpen]: openGeofenceSearch,
                })}
                search
                dense
                variant="standard"
                onChange={(e) => setGeofenceSearchKey(e.target.value)}
                onOpenSearch={() => {
                  const openEvent = new CustomEvent("drawer:open");
                  document.dispatchEvent(openEvent);
                  setGeofenceOpenSearch(true);
                }}
                onCloseSearch={() => {
                  setGeofenceSearchKey("");
                  setGeofenceOpenSearch(false);
                }}
              />
            )}
          </div>

          {geofenceListMarkup}

          {(openAdjustPriority) && (
            <div className={classes.adjustBtnDiv}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(setOpenAdjustPriority(false));
                  setGeofences(origGeofences);
                }}
                style={{ minWidth: 125, marginRight: 8, }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                loading={loading}
                onClick={() => dispatch(saveSpeedPriority(speedGeofences))}
                style={{ minWidth: 125 }}
              >
                {t("Save")}
              </Button>
            </div>
          )}
        </div>
        <div
          className={clsx(classes.panelDiv, {
            [classes.subPanelDivClose]: mode !== "add" && !showDetail,
          })}
        >
          <div className={classes.titleDiv}>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {currentGeofence && (
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    reset();
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography
                category="Default"
                variant="H6"
                style={
                  currentGeofence ? { margin: "0 16px", marginTop: 4 } : {}
                }
              >
                {currentGeofence ? t("Geofences") : t("Add geofence")}
              </Typography>
            </div>
          </div>
          <SimpleBarReact
            ref={bodyRef}
            className={classes.panelBodyDiv}
            style={disabledAdd ? { overflow: "hidden" } : {}}
          >
            <div className={classes.inputName}>
              <Input
                placeholder={t("Name")}
                label={t("Name")}
                value={name}
                onFocus={() => onFocus(true)}
                onBlur={() => onFocus(false)}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.value.length <= 16) {
                    if (e.target.value.indexOf("%") > -1) {
                      setUnallowed(true);
                    } else {
                      setUnallowed(false);
                    }
                    setName(e.target.value);
                  }
                }}
                error={unallowed}
                helperText={
                  unallowed ? t("Unallowed character detected") : undefined
                }
              />
              {mode !== "add" && (
                <IconButton
                  ref={viewMoreBtnRef}
                  style={{ padding: 0 }}
                  onClick={() => {
                    setMoreBtnRef(viewMoreBtnRef);
                    setOpenMenu(true);
                    setMoreZone(currentGeofence);
                  }}
                >
                  <MoreVertIcon className={classes.moreIcon} />
                </IconButton>
              )}
            </div>
            <Accordion
              elevation={0}
              expanded={openColorPanel}
              onChange={() => setOpenColorPanel((o) => !o)}
            >
              <AccordionSummary
                className={classes.accordionSummaryPadding}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className={classes.menuTitle}>
                  <FormatPaintIcon className={classes.menuIcon} />
                  <Typography category="Default" variant="BodyBold">
                    {t("Color and opacity")}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={clsx(classes.coDiv, classes.colorDiv)}>
                  <Typography category="Default" variant="Body">
                    {t("Color")}
                  </Typography>
                  <div className={classes.colorSelectDiv}>
                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#F21212",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#F21212" }}
                          onClick={() => {
                            dispatch(setFillColor("#F21212"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#F3890D",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#F3890D" }}
                          onClick={() => {
                            dispatch(setFillColor("#F3890D"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#FDF300",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#FDF300" }}
                          onClick={() => {
                            dispatch(setFillColor("#FDF300"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#12E812",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#12E812" }}
                          onClick={() => {
                            dispatch(setFillColor("#12E812"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#0E0EF1",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#0E0EF1" }}
                          onClick={() => {
                            dispatch(setFillColor("#0E0EF1"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#201359",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#201359" }}
                          onClick={() => {
                            dispatch(setFillColor("#201359"));
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: fillColor === "#E314E3",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#E314E3" }}
                          onClick={() => {
                            dispatch(setFillColor("#E314E3"));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={clsx(classes.coDiv, classes.opacityDiv)}>
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ marginBottom: 3 }}
                  >
                    {t("Opacity")}
                  </Typography>
                  <div className={classes.colorSelectDiv}>
                    <Sliders
                      min={0}
                      max={100}
                      value={Math.floor(fillOpacity * 100)}
                      onChangeCommitted={(e, value) => {
                        dispatch(setFillOpacity((value as number) / 100));
                      }}
                      onChange={(e, value) => {
                        dispatch(setFillOpacity((value as number) / 100));
                      }}
                    />
                    <Typography
                      category="Default"
                      variant="Body"
                      style={
                        theme.direction === "rtl"
                          ? { marginRight: 13 }
                          : { marginLeft: 13 }
                      }
                    >
                      {Math.round(fillOpacity * 100)}%
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/* Camera List */}
            {cameraListMarkup(cameras)}

            <Divider className={classes.divider} />

            {/* Box Pro Camera List */}
            {boxProCameras.length > 0 && cameraListMarkup(boxProCameras, true)}

            {disabledAdd && (
              <div
                className={classes.disabledAddDiv}
                style={
                  bodyRef.current
                    ? {
                      bottom: Math.min(bodyRef.current.scrollHeight, 0),
                    }
                    : {}
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            )}
          </SimpleBarReact>
          <div className={classes.btnDiv}>
            <Button
              variant="outlined"
              color="primary"
              disabled={
                loading &&
                (type === addGeofence.type || type === updateGeofence.type)
              }
              onClick={() => {
                reset();
                if (mode === "add") {
                  // mantis - 8596, add모드에서 cancel시 스크롤 최상단으로 이동.
                  bodyRef.current.contentEl.scrollIntoView();
                  history.goBack();
                }
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                disabledAdd ||
                !name ||
                [...selectedCamera, ...selectedBoxProCamera].length === 0 ||
                unallowed
                // (boxProSettings?.checkSpeed && boxProSettings?.alarmSpeed === "") ||
                // mantis - 10911, notification / live event upload 4개중 1개라도 선택되어있으면 save 버튼을 활성화 (Leehj)
                // (!(settings?.checkEnter || settings?.checkExit || settings?.checkPass) &&
                // mantis - 14181, 선택된 카메라만 있으면 활성 (Hongcs, 24.07.30)
                // (selectedCamera.length > 0 && !_.some(cameraSettings, s => s === true)) ||
                // Box Pro 세팅 추가
                // (selectedBoxProCamera.length > 0 && !_.some(boxProSettings, s => s === true))
              }
              loading={
                loading &&
                (type === addGeofence.type || type === updateGeofence.type)
              }
              onClick={() => {
                onSave({
                  mode: currentGeofence?.fenceId ? "update" : "add",
                  geofence: {
                    fenceId:
                      currentGeofence?.fenceId ?? `fenceId_${moment().unix()}`,
                    fenceName: name,
                    fenceType: FenceTypeCode[drawingMode],
                    psn: _.map(selectedCamera, (c) => c.psn),
                    newPsn: _.map(selectedBoxProCamera, (c) => c.psn),
                    newRecording: {
                      enterSdCard: boxProSettings?.checkEnterSdCard ? "on" : "off",
                      enterLiveEventUpload: boxProSettings?.checkEnterSdCardLiveEvent ? "on" : "off",
                      exitSdCard: boxProSettings?.checkExitSdCard ? "on" : "off",
                      exitLiveEventUpload: boxProSettings?.checkExitSdCardLiveEvent ? "on" : "off",
                      passSdCard: boxProSettings?.checkPassSdCard ? "on" : "off",
                      passLiveEventUpload: boxProSettings?.checkPassLiveEventUpload ? "on" : "off",
                      speedLiveEventUpload: boxProSettings?.checkSpeedLiveEvent ? "on" : "off",
                    },
                    setShape: {
                      Color: fillColor,
                      Transparency: fillOpacity,
                    },
                    // alarm: _.compact([
                    //   settings.checkEnter && "Enter",
                    //   settings.checkExit && "Exit",
                    //   settings.checkPass && "Pass",
                    //   settings.checkSpeed && "Speed",
                    // ]),
                    speed: boxProSettings?.alarmSpeed
                      ? userSettings?.velocityUnit === "0"
                        ? { Km: Number(boxProSettings?.alarmSpeed) }
                        : { Mph: Number(boxProSettings?.alarmSpeed) }
                      : undefined,
                    liveEventUpload: _.compact([
                      cameraSettings?.checkLiveEventEnter && "Enter",
                      cameraSettings?.checkLiveEventExit && "Exit",
                      cameraSettings?.checkLiveEventPass && "Pass",
                    ]),
                  },
                });
              }}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>

      <GeofenceCamerasSettingPopper
        anchorEl={geofencPanelRef?.current}
        open={openCamerasSettingsPopper}
        mobile={mobile}
        onClickAway={() => setOpenCamerasSettingsPopper(false)}
        onClose={() => setOpenCamerasSettingsPopper(false)}
        placement="right"
        modifiers={{
          offset: {
            enabled: true,
            offset: "20px, 330px",
          },
        }}
        drawingMode={drawingMode}
        velocityUnit={userSettings?.velocityUnit}
        preSettings={cameraSettings}
        onSettings={(settings?: ICameraSettings) => {
          setCameraSettings(settings);
          setOpenCamerasSettingsPopper(false);
          // mantis - 14181, 설정값 선택되지 않은 상태 카메라 선택 (Hongcs, 24.07.30)
          // if (!_.some(settings, s => s === true)) {
          //   setSelectedCamera([]);
          // }
        }}
      />

      <GeofenceBoxProSettingPopper
        anchorEl={geofencPanelRef?.current}
        open={openBoxProSettingsPopper}
        mobile={mobile}
        onClickAway={() => setOpenBoxProSettingsPopper(false)}
        onClose={() => setOpenBoxProSettingsPopper(false)}
        placement="right"
        modifiers={{
          offset: {
            enabled: true,
            offset: "50px, 330px",
          },
        }}
        drawingMode={drawingMode}
        velocityUnit={userSettings?.velocityUnit}
        preSettings={boxProSettings}
        onSettings={(settings?: IBoxProSettings) => {
          setBoxProSettings(settings);
          setOpenBoxProSettingsPopper(false);
          // mantis - 14181, 설정값 선택되지 않은 상태 카메라 선택 (Hongcs, 24.07.30)
          // if (!_.some(settings, s => s === true)) {
          //   setSelectedBoxProCamera([]);
          // }
        }}
      />

      {mode !== "add" && !showDetail && !mobile && (
        <div className={classes.floatingBtnDiv}>
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.fabUXUI, {
              [classes.fabMargin]: openPanel,
            })}
            onClick={() => {
              dispatch(clearUndoList());
              setOpenPanel((o) => !o);
            }}
          >
            {(theme.direction === "rtl") !== openPanel ? (
              <ArrowLeftIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </Fab>
        </div>
      )}
      {(mode === "add" || showDetail) && (
        <div className={classes.floatingDrawingDiv}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={clsx(classes.drawToolDiv)}>
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Undo")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disableUndo}
                    className={clsx(classes.drawToolFab)}
                    onClick={() => {
                      dispatch(popUndoList());
                    }}
                  >
                    <UndoIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Redo")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disableRedo}
                    className={clsx(classes.drawToolFab)}
                    onClick={() => {
                      dispatch(popRedoList());
                    }}
                  >
                    <RedoIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Cancel")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disabledAdd || showDetail}
                    className={clsx(classes.drawToolFab)}
                    onClick={handleDelete}
                  >
                    <ClearIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Polygon")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, classes.polygonIcon, {
                      [classes.polygonSelected]: drawingMode === "polygon",
                      [classes.disabledPolygonToolDiv]:
                        showDetail && drawingMode !== "polygon",
                    })}
                    disabled={showDetail && drawingMode !== "polygon"}
                    onClick={() => {
                      if (drawingMode !== "polygon") {
                        dispatch(setDrawingMode("polygon"));
                        dispatch(clearUndoList());
                      }
                    }}
                  >
                    <Polygon style={{ width: 20, height: 20 }} />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Polyline")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "polyline",
                    })}
                    disabled={showDetail && drawingMode !== "polyline"}
                    onClick={() => {
                      if (drawingMode !== "polyline") {
                        dispatch(clearUndoList());
                        dispatch(setDrawingMode("polyline"));
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Ellipse")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "circle",
                    })}
                    disabled={showDetail && drawingMode !== "circle"}
                    onClick={() => {
                      if (drawingMode !== "circle") {
                        dispatch(clearUndoList());
                        dispatch(setDrawingMode("circle"));
                      }
                    }}
                  >
                    <PanoramaFishEyeIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Rectangle")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "rectangle",
                    })}
                    disabled={showDetail && drawingMode !== "rectangle"}
                    onClick={() => {
                      if (drawingMode !== "rectangle") {
                        dispatch(clearUndoList());
                        dispatch(setDrawingMode("rectangle"));
                      }
                    }}
                  >
                    <CropSquareIcon />
                  </Fab>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {!mobile && (
        <CameraMenu
          open={openMenu}
          onClickAway={() => setOpenMenu(false)}
          anchorRef={moreBtnRef ?? undefined}
          geofence={moreZone}
          isAdjustPriority={
            (adjustablePriority
              && !(!moreZone?.speed?.Km || !moreZone?.speed?.Mph)) ?? false
          }
          onSpeedPriority={() => onSpeedPriority()}
          onDelete={() => {
            setOpenMenu(false);
            setOpenDeleteModal(true);
          }}
          hideDivider
          placement="bottom-end"
          dense
        />
      )}
      {mobile && (
        <MobileCameraMenu
          open={openMenu}
          onClose={() => {
            setOpenMenu(false);
          }}
          geofence={moreZone}
          isAdjustPriority={
            (adjustablePriority
              && !(!moreZone?.speed?.Km || !moreZone?.speed?.Mph)) ?? false
          }
          onSpeedPriority={() => onSpeedPriority()}
          onDelete={() => {
            setOpenMenu(false);
            setOpenDeleteModal(true);
          }}
        />
      )}
      <Modal
        open={openDeleteModal}
        close
        onClose={() => setOpenDeleteModal(false)}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() => {
          if (moreZone) {
            dispatch(
              deleteGeofence({
                fenceId: moreZone.fenceId,
                fenceName: moreZone.fenceName,
              })
            );
          }
        }}
        heading={t("Delete")}
        content={t("Are you sure_delete zone", {
          zone: moreZone?.fenceName,
        })}
        LButton={t("Cancel")}
        RButton={t("Delete")}
        Secondary
        loading={loading && type === deleteGeofence.type}
        mobile={mobile}
        actionClassName={classes.pdT}
      />
      <Modal
        open={openDelete}
        content={t("Are you sure_delete_zone")}
        heading={t("Delete")}
        onClickAway={() => setOpenDelete(false)}
        onClickNegative={() => setOpenDelete(false)}
        onClickPositive={() => {
          handleDelete();
          setOpenDelete(false);
        }}
        RButton={t("OK")}
        LButton={t("Cancel")}
      />
    </div>
  );
};
