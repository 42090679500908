import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Button,
  ThumbnailBroken,
  Typography,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCloudVODFile, getCNThumbnail, getEventVODFile } from "../../apis";
import { PAYMENT } from "../../features/Payment/slice";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { setSelectedHashtags, VOD } from "../../features/VOD/slice";
import { eventmapAxiosInst, eventmapJwtAxiosInst } from "../../utils";

import { getOriginalFilename } from "../../utils/VOD";
import { HashtagButton } from "./HashtagButton";
import { EventVideo, LightColors } from "@thingsw/pitta-modules";

//tab기준
const tablet = 660;

const useStyles = makeStyles((theme: Theme) => ({
  brokenImage: {
    fill: "none",
    width: 342,
    height: 192,
    [theme.breakpoints.up(tablet)]: {
      width: 424,
      height: 239,
    },
    maxWidth: "100vw",
  },
  buttonWrap: {
    paddingTop: theme.spacing(3),
    alignItems: "center",
    [theme.breakpoints.up(tablet)]: {
      paddingTop: theme.spacing(5.5),
    },
  },
  cancelBtn: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    flex: 1,
  },
  description: {
    marginTop: 27,
    padding: "0px 16px",
    [theme.breakpoints.up(tablet)]: {
      marginTop: 25,
      padding: "0px 40px",
    },
  },
  hashtagDiv: {
    width: "100%",
    margin: "4px 0",
    padding: "0px 16px",
    [theme.breakpoints.up(tablet)]: {
      padding: "0px 40px",
    },
  },
  imgContent: {
    width: 342,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(tablet)]: {
      width: 433,
    },
    maxWidth: "100vw",
  },
  outlineDescription: {
    padding: "10px 13px 2px 16px",
  },
  secondText: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10,
    [theme.breakpoints.up(tablet)]: {
      marginTop: 48,
      marginBottom: 2,
    },
  },
  selectHashtagDiv: {
    width: "100%",
    margin: "4px 0",
    padding: "0px 12px",
    [theme.breakpoints.up(tablet)]: {
      padding: "0px 36px",
    },
  },
  shareBtn: {
    fontWeight: 400,
    // width: 175,
  },
}));

interface ShareEventProps {
  psn?: string;
  filename?: string;
  thmRid?: string | null;
  rid?: string | null;
  onOpenHashtag: VoidFunction;
  onSuccessSave?: (description: string, hashtags: number[]) => void;
  onFailSave?: (reason: string) => void;
  onClose: VoidFunction;
  onDescription?: (description: string) => void;
  sendDescription?: string;
  selectedEventVideo?: EventVideo;
  onEventVideo?: (eventVideos: EventVideo[]) => void;
  mode?: 0 | 1 | 2; // 0: sd, 1: cloud, 2: live event upload
}

export const EVENTMAP_MODE_TO_TYPE = {
  0: "sd",
  1: "cloud",
  2: "liveevent",
};

export const ShareEvent = ({
  psn,
  filename,
  thmRid,
  rid,
  onOpenHashtag,
  onSuccessSave,
  onFailSave,
  onClose,
  onDescription,
  sendDescription,
  selectedEventVideo,
  onEventVideo,
  mode,
}: ShareEventProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(660));
  const classes = useStyles();

  const { email, tokenType, loginInfo, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );
  const { hashtags, selectedHashtags } = useSelector(
    (state: RootState) => state[VOD]
  );
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const [thumbnail, setThumbnail] = useState<string>();
  const [description, setDescription] = useState<string>(sendDescription ?? "");
  const [loading, setLoading] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  useEffect(() => {
    if (selectedEventVideo) {
      setThumbnail(selectedEventVideo.thumb);
      setDescription(selectedEventVideo?.description ?? "");
      if (editDescription) {
        setDescription(sendDescription ?? "");
      } else {
        dispatch(
          setSelectedHashtags(
            _.map(selectedEventVideo.hashtags, (tag) => tag.id)
          )
        );
      }
    }
  }, [selectedEventVideo, dispatch, sendDescription, editDescription]);

  useEffect(() => {
    if (selectedHashtags.length !== 0) {
      dispatch(setSelectedHashtags(selectedHashtags));
    }
  }, [selectedHashtags, dispatch]);

  useEffect(() => {
    const getThumbnail = async () => {
      console.log("getThumbnail", mode, filename);
      if (!loginInfo?.user_token || !email || !psn) return;
      try {
        if (mode === 0) {
          if (!filename) return;
          const resp = await getCNThumbnail(
            email,
            loginInfo.user_token,
            psn,
            filename,
            ["t"],
            tokenType
          );
          setThumbnail(`data:image/jpeg;base64,${resp.data.thumbnail}`);
        } else if (mode === 1) {
          if (!filename) return;
          // sd카드에서 클라우드로 영상 업로드 후 서브 스트림은 업로드 되었으나,
          // main스트림 영상 업로드되기 전에 이벤트맵 공유할 경우,
          // 썸네일 파일을 서브 스트림으로 요청해서 썸네일 안나오는 문제 수정
          const originalFilename = getOriginalFilename(filename);
          const resp = await getCloudVODFile(
            email,
            loginInfo.user_token,
            psn,
            originalFilename.replace(".mp4", ".thm"),
            tokenType
          );
          setThumbnail(resp.data?.response.presignedURL);
        } else {
          if (!thmRid) return;
          const resp = await getEventVODFile(
            email,
            loginInfo.user_token,
            psn,
            thmRid,
            tokenType
          );
          setThumbnail(resp.data?.fileInfo.url);
        }
      } catch (err: any) {
        console.log("error", err.message);
      }
    };

    getThumbnail();
  }, [email, filename, loginInfo?.user_token, mode, psn, thmRid, tokenType]);

  const handleChangeSelected = useCallback(
    (id: number) => {
      if (_.includes(selectedHashtags, id)) {
        dispatch(setSelectedHashtags(_.difference(selectedHashtags, [id])));
      } else {
        dispatch(setSelectedHashtags(_.union(selectedHashtags, [id])));
      }
    },
    [dispatch, selectedHashtags]
  );

  const hashtagsMarkup = useMemo(() => {
    return _.map(selectedHashtags, (tagId) => {
      const exists = _.find(hashtags, (t) => t.id === tagId);
      if (exists) {
        return (
          <HashtagButton
            key={`hashtag-${tagId}`}
            hashtag={exists}
            selected
            onClick={() => handleChangeSelected(tagId)}
          />
        );
      }
    });
  }, [handleChangeSelected, hashtags, selectedHashtags]);

  const thumbnailMarkup = useMemo(() => {
    if (thumbnail) {
      return (
        <img src={thumbnail} className={classes.brokenImage} alt="Thumbnail" />
      );
    }
    return <ThumbnailBroken className={classes.brokenImage} />;
  }, [classes.brokenImage, thumbnail]);

  const handleShareInMap = useCallback(async () => {
    if (!email || !loginInfo || !userProfile || !subscriptionInfo) return;
    try {
      setLoading(true);

      await eventmapJwtAxiosInst.post(
        `/shared-events?user_token=${loginInfo.user_token}`,
        {
          psn,
          email:
            userProfile.userType === "SubMaster"
              ? subscriptionInfo?.masterEmail
              : email,
          filename,
          description,
          hashtags: selectedHashtags,
          type: EVENTMAP_MODE_TO_TYPE[mode ?? 2],
          rid,
        }
      );
      //   onClose();
      dispatch(setSelectedHashtags([]));
      onDescription?.("");
      onSuccessSave?.(description, selectedHashtags);
    } catch (err: any) {
      if (err.response.status === 409) {
        onFailSave?.("EVENT_ALREADY_SHARED");
      } else {
        onFailSave?.("INTERNAL_SERVER_ERROR");
      }

      console.log("Eventmap", "EventShareModal", err, err.response.status);
    } finally {
      setLoading(false);
    }
  }, [
    description,
    dispatch,
    email,
    filename,
    loginInfo,
    mode,
    onDescription,
    onFailSave,
    onSuccessSave,
    psn,
    rid,
    selectedHashtags,
    subscriptionInfo,
    userProfile,
  ]);

  const handleEditShareInMap = useCallback(async () => {
    if (!email || !loginInfo || !userProfile || !subscriptionInfo) return;
    try {
      setLoading(true);

      if (selectedEventVideo) {
        await eventmapAxiosInst.put(`/event-videos/${selectedEventVideo?.id}`, {
          description,
          hashtags: selectedHashtags,
        });
        onSuccessSave?.(description, selectedHashtags);
        onClose();
      }
    } catch (err) {
      console.log("Eventmap", "EventShareModal", err);
    } finally {
      dispatch(setSelectedHashtags([]));
      onDescription?.("");
      setLoading(false);
    }
  }, [
    email,
    loginInfo,
    userProfile,
    subscriptionInfo,
    selectedEventVideo,
    description,
    selectedHashtags,
    onSuccessSave,
    onClose,
    dispatch,
    onDescription,
  ]);

  const handleClose = useCallback(() => {
    if (selectedEventVideo && selectedEventVideo.description) {
      setDescription(selectedEventVideo?.description);
    } else {
      setDescription("");
    }
    dispatch(setSelectedHashtags([]));

    onClose();
  }, [dispatch, onClose, selectedEventVideo]);

  return (
    <div className={classes.content}>
      <Typography
        category="Default"
        variant={mobile ? "H5" : "H2"}
        htmlColor={LightColors.primary["2"]}
        style={{ marginBottom: mobile ? 24 : 14 }}
      >
        {selectedEventVideo?.owner
          ? t("Edit shared video")
          : t("Share your video to the Event Map?")}
      </Typography>

      {!mobile && (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          style={{ marginBottom: 50, padding: "0px 40px" }}
        >
          {t("Afterwards, according to the reaction to the video_")}
        </Typography>
      )}
      <div className={classes.imgContent}>
        {thumbnailMarkup}

        <Typography
          category="Default"
          variant="H5"
          htmlColor={LightColors.primary["2"]}
          className={classes.secondText}
          // style={{ marginTop: 48, marginBottom: 12, textAlign: "center" }}
        >
          {t("Add hashtags and a brief description to describe your video.")}
        </Typography>
      </div>
      <div className={classes.selectHashtagDiv}>{hashtagsMarkup}</div>
      <div className={classes.hashtagDiv}>
        <HashtagButton onClick={onOpenHashtag} />
      </div>
      <Input
        placeholder={t("Describe in more_")}
        label={t("Description(optional)")}
        multiline
        rows={4}
        className={classes.description}
        value={description}
        onChange={(e) => {
          setEditDescription(true);
          setDescription(e.target.value);
          onDescription?.(e.target.value);
        }}
        outlinedInputClassName={classes.outlineDescription}
      />

      <div className={classes.buttonWrap}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          className={classes.cancelBtn}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={selectedEventVideo ? handleEditShareInMap : handleShareInMap}
          variant="contained"
          color="primary"
          className={classes.shareBtn}
          loading={loading}
        >
          {t(selectedEventVideo ? "Save" : "Share to Event Map")}
        </Button>
      </div>
    </div>
  );
};
