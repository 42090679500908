import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Modal, Report } from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { PAYMENT } from "../../features/Payment/slice";
import _ from "lodash";
import { VideoEventInfo } from "../events/VideoEventInfo";
import { loadHashtags, VOD } from "../../features/VOD/slice";
import clsx from "clsx";

import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { PortalProps } from "@material-ui/core";
import { EventShareModal } from "./EventShareModal";
import { HashtagModal } from "./HashtagModal";
import { detect } from "detect-browser";
import { useWindowSize } from "../../hooks";
import { eventmapAxiosInst } from "../../utils";
import { EventVideo, SharedEvent } from "@thingsw/pitta-modules";

interface EventVideoModalProps {
  eventId: number;
  open: boolean;
  zoom: number;
  onClose: React.MouseEventHandler<HTMLElement>;
  onOpenReportVideoModal?: (videoId: number) => void;
  container?: PortalProps["container"];
  myEventVideos: EventVideo[];
  mode: "map" | "my-videos" | "line";
  myVideoIndex: number;
  lineVideos: SharedEvent[];
  lineIndex: number;
  shareToEventmapPerm?: boolean;
  mobile: boolean;
  app?: boolean;
  eventTypes?: string[];
  mapType: "map" | "satellite";
}

//tab기준
const tablet = 660;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up(tablet)]: {
      minWidth: 470,
      maxWidth: 593,
      borderRadius: 12,
      border: "unset",
    },
  },
  rootFullscreen: {
    width: "100%",
    maxWidth: "unset",
    minWidth: "unset",
    borderRadius: 0,
  },
  title: {
    height: 34,
  },
  iconBtn: {
    padding: "0 4px",
  },
  iconBtnFullscreen: {
    color: "white",
  },
  reportIcon: {
    marginRight: 7,
  },
  content: {
    padding: 0,
    [theme.breakpoints.up(tablet)]: {
      paddingTop: 8,
    },
  },
  contentFullscreen: {
    padding: 0,
  },
  titleFullscreen: {
    padding: 0,
    height: "0px!important",
    minHeight: 0,
  },
  hiddenScroll: {
    overflowY: "hidden",
    [theme.breakpoints.up(tablet)]: {
      overflowY: "auto",
    },
  },
  scrollbarStyle: {
    // height: "100%",
    flex: 1,
    [theme.breakpoints.up(tablet)]: {
      overflowX: "hidden",
      maxHeight: "calc(100vh - 100px)",
    },
  },
  paperScrollPaper: {
    maxHeight: "100%",
    [theme.breakpoints.up(tablet)]: {
      maxHeight: "inherit",
    },
  },
}));

export const EventVideoModal = ({
  eventId,
  open,
  zoom,
  onClose,
  onOpenReportVideoModal,
  container,
  mode,
  myEventVideos,
  myVideoIndex,
  lineVideos,
  lineIndex,
  shareToEventmapPerm,
  mobile,
  app,
  eventTypes,
  mapType,
}: EventVideoModalProps) => {
  const classes = useStyles();
  const windowSize = useWindowSize();

  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const { hashtags } = useSelector((state: RootState) => state[VOD]);

  const dispatch = useDispatch();

  const [eventVideos, setEventVideos] = useState<EventVideo[]>([]);
  const [hiddenScroll, setHiddenScroll] = useState<boolean>(false);
  const [videoIndx, setVideoIndx] = useState(0);
  const [
    videoRefCurrent,
    setVideoRefCurrent,
  ] = useState<HTMLVideoElement | null>(null);
  const [openEventShareModal, setOpenEventShareModal] = useState(false);
  const [openHashtagModal, setOpenHashtagModal] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    let timeout = 0;
    // LG Q8용 딜레이 너무 느려서 바로 방향 flag바꾸면 적용이 안됨..
    if (navigator.userAgent.indexOf("LM-Q815K") > -1) {
      timeout = 100;
    }

    if (
      windowSize.height &&
      windowSize.width &&
      windowSize.width > windowSize.height &&
      (mobile || app)
    ) {
      setTimeout(() => {
        setFullscreen(true);
      }, timeout);
    } else {
      setTimeout(() => {
        setFullscreen(false);
      }, timeout);
    }
  }, [app, mobile, windowSize]);

  useEffect(() => {
    const browserName = detect()?.name;
    if (open) {
      if (
        browserName === "ios-webview" &&
        //@ts-ignore
        webkit.messageHandlers.enableRotate
      ) {
        //@ts-ignore
        webkit.messageHandlers.enableRotate.postMessage({});
      }
      if (
        browserName === "chromium-webview" &&
        //@ts-ignore
        window.Webviewer?.enableRotate
      ) {
        //@ts-ignore
        window.Webviewer?.enableRotate?.();
      }
    } else {
      if (
        browserName === "ios-webview" &&
        //@ts-ignore
        webkit.messageHandlers.disableRotate
      ) {
        //@ts-ignore
        webkit.messageHandlers.disableRotate.postMessage({});
      }
      if (
        browserName === "chromium-webview" &&
        //@ts-ignore
        window.Webviewer?.disableRotate
      ) {
        //@ts-ignore
        window.Webviewer?.disableRotate?.();
      }
    }
  }, [open]);

  useEffect(() => {
    if (hashtags.length === 0) {
      dispatch(loadHashtags());
    }
  }, [dispatch, hashtags.length]);

  //@ts-ignore
  window.requestFullscreen = useCallback(() => {
    setFullscreen(true);
  }, []);

  //@ts-ignore
  window.exitFullscreen = useCallback(() => {
    setFullscreen(true);
  }, []);

  useEffect(() => {
    if (mode === "my-videos" && open) {
      setVideoIndx(myVideoIndex);
      setEventVideos(myEventVideos);
    }
  }, [mode, myEventVideos, myVideoIndex, open]);

  console.log(
    "EventVideoModal",
    "mode",
    mode,
    "eventVideos",
    eventVideos,
    "videoIndex",
    videoIndx
  );

  useEffect(() => {
    if (mode === "map") {
      setVideoIndx(0);
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "line") {
      setVideoIndx(lineIndex);
    }
  }, [lineIndex, mode]);

  useEffect(() => {
    const getEventVideo = async () => {
      // console.log("Eventmap", "getEventVideo", lineVideos, videoIndx);
      const videos = await eventmapAxiosInst.get(
        `/event-videos/${lineVideos[videoIndx].videoId}`
      );
      eventVideos[videoIndx] = videos.data;
      setEventVideos([...eventVideos]);
      // console.log("Eventmap", "EventVideoModal", "videos", videos);
    };
    // console.log(
    //   "Eventmap",
    //   "EventVideoModal",
    //   "getEventVideo",
    //   "mode",
    //   mode,
    //   "videoIndx",
    //   videoIndx,
    //   "eventVideos[videoIndx]",
    //   eventVideos[videoIndx],
    //   "lineVideos",
    //   lineVideos
    // );
    if (mode === "line" && open) {
      if (!eventVideos[videoIndx] && lineVideos[videoIndx]) {
        getEventVideo();
      }
    }
  }, [eventVideos, lineVideos, mode, open, videoIndx]);

  const getEventVideos = useCallback(async () => {
    console.log("getEventVideos", eventId);
    if (mode === "line" || mode === "my-videos" || !open) return;

    if (!userProfile || !subscriptionInfo) return;
    if (eventId <= 0) return;
    let query = `?zoom=${zoom}&mobile=${mobile}`;

    if (
      userProfile.userType === "SubMaster" ||
      userProfile.userType === "User"
    ) {
      query = query + `&email=${subscriptionInfo.masterEmail}`;
    }

    if (eventTypes) {
      query += `&events=${JSON.stringify(eventTypes)}`;
    }

    console.log("getEventVideos", query, zoom);
    if (zoom < 15) {
      const videos = await eventmapAxiosInst.get(
        `/events/${eventId}/nearby${query}`
      );
      setEventVideos(videos.data);
      if (videos.data.length === 0) {
        setBlocked(true);
      }
    } else {
      const evt = await eventmapAxiosInst.get(
        `/events/${eventId}/feature${query}`
      );

      if (evt.data.videos.length === 0) {
        setRemoved(true);
        setEventVideos(evt.data.videos);
      } else {
        const filtered = _.filter(evt.data.videos, (v) => !v.blocked);
        if (filtered.length === 0) {
          setBlocked(true);
        }

        setEventVideos(filtered);
      }
    }

    // setFeature(evt.data.geojson);
  }, [
    eventId,
    eventTypes,
    mobile,
    mode,
    open,
    subscriptionInfo,
    userProfile,
    zoom,
  ]);

  useEffect(() => {
    getEventVideos();
  }, [getEventVideos]);

  const handleOpenReport = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      videoRefCurrent?.pause();
      if (eventVideos[videoIndx]?.id) {
        onOpenReportVideoModal?.(eventVideos[videoIndx].id);
        if (fullscreen) {
          setFullscreen(false);
          const browserName = detect()?.name;
          if (
            browserName === "ios-webview" &&
            //@ts-ignore
            webkit.messageHandlers.disableRotate
          ) {
            //@ts-ignore
            webkit.messageHandlers.disableRotate.postMessage({});
          }
          if (
            browserName === "chromium-webview" &&
            //@ts-ignore
            window.Webviewer?.disableRotate
          ) {
            //@ts-ignore
            window.Webviewer?.disableRotate?.();
          }
        }
      }
    },
    [
      eventVideos,
      fullscreen,
      onOpenReportVideoModal,
      videoIndx,
      videoRefCurrent,
    ]
  );

  const handleClose: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      setEventVideos([]);
      setBlocked(false);
      setRemoved(false);
      if (fullscreen) {
        setFullscreen(false);
        const browserName = detect()?.name;
        if (
          browserName === "ios-webview" &&
          //@ts-ignore
          webkit.messageHandlers.disableRotate
        ) {
          //@ts-ignore
          webkit.messageHandlers.disableRotate.postMessage({});
        }
        if (
          browserName === "chromium-webview" &&
          //@ts-ignore
          window.Webviewer?.disableRotate
        ) {
          //@ts-ignore
          window.Webviewer?.disableRotate?.();
        }
      }
      onClose(e);
    },
    [fullscreen, onClose]
  );

  const headerBtnMarkup = useMemo(() => {
    if (fullscreen) return;
    if (app) {
      return (
        <>
          {!eventVideos[videoIndx]?.owner && (
            <IconButton
              onClick={handleOpenReport}
              style={{ marginRight: 16 }}
              className={clsx(classes.iconBtn, classes.reportIcon)}
              color="secondary"
            >
              <Report />
            </IconButton>
          )}
        </>
      );
    }
    return (
      <>
        {!eventVideos[videoIndx]?.owner && !eventVideos[videoIndx]?.blocked && (
          <IconButton
            onClick={handleOpenReport}
            color="secondary"
            className={clsx(classes.iconBtn, classes.reportIcon)}
          >
            <Report />
          </IconButton>
        )}
        {/* mantis - 10368, 모바일인 경우에도 X가 아닌 < 로 출력되도록 수정(Leehj) */}
        {!mobile && (
          <IconButton className={classes.iconBtn} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </>
    );
  }, [
    app,
    classes.iconBtn,
    classes.reportIcon,
    eventVideos,
    fullscreen,
    handleClose,
    handleOpenReport,
    mobile,
    videoIndx,
  ]);

  return (
    <>
      <Modal
        open={open}
        container={container}
        onClose={handleClose}
        className={clsx(classes.root, {
          [classes.rootFullscreen]: fullscreen,
        })}
        titleClassName={clsx({
          [classes.title]: !fullscreen,
          [classes.titleFullscreen]: fullscreen,
        })}
        contentClassName={clsx(classes.content, {
          [classes.hiddenScroll]: hiddenScroll && mobile,
          [classes.contentFullscreen]: fullscreen,
        })}
        fullSize={mobile || fullscreen}
        paperScrollPaperClassName={classes.paperScrollPaper}
        heading={" "}
        headingIcon={
          // mantis - 10368, 모바일인 경우에도 X가 아닌 < 로 출력되도록 수정(Leehj)
          (app || mobile) &&
          !fullscreen && (
            <IconButton
              className={clsx(classes.iconBtn, {})}
              style={{ position: "absolute", top: 13, left: 6 }}
              onClick={handleClose}
            >
              <ArrowBackIosOutlinedIcon />
            </IconButton>
          )
        }
        headerBtn={headerBtnMarkup}
        content={
          <div
            style={{
              display: "flex",
              flex: "1 1 auto",
              position: "relative",
              overflow: "hidden",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {fullscreen && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,

                  backgroundColor: "rgba(0,0,0,0.3)",
                  zIndex: 99999,
                  padding: 4,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <IconButton
                    className={clsx(classes.iconBtn, classes.reportIcon, {
                      [classes.iconBtnFullscreen]: fullscreen,
                    })}
                    onClick={handleClose}
                  >
                    <ArrowBackIosOutlinedIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {!eventVideos[videoIndx]?.owner && (
                    <IconButton
                      onClick={handleOpenReport}
                      color="secondary"
                      className={clsx(classes.iconBtn, classes.reportIcon, {
                        [classes.iconBtnFullscreen]: fullscreen,
                      })}
                    >
                      <Report />
                    </IconButton>
                  )}
                  {!app && !mobile && (
                    <IconButton
                      className={clsx(classes.iconBtn, {
                        [classes.iconBtnFullscreen]: fullscreen,
                      })}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            )}
            <VideoEventInfo
              eventVideos={eventVideos}
              shareToEventmapPerm={shareToEventmapPerm}
              length={mode === "line" ? lineVideos.length : eventVideos.length}
              videoIndx={videoIndx}
              mapType={mapType}
              onPrev={() => setVideoIndx((v) => Math.max(v - 1, 0))}
              onNext={() =>
                setVideoIndx((v) =>
                  Math.min(
                    v + 1,
                    mode === "line"
                      ? lineVideos.length - 1
                      : eventVideos.length - 1
                  )
                )
              }
              onUpdateVideoRef={setVideoRefCurrent}
              onHiddenScroll={(hidden) => setHiddenScroll(hidden)}
              onEdit={() => setOpenEventShareModal(true)}
              fullscreen={fullscreen}
              blocked={blocked}
              removed={removed}
            />
          </div>
        }
      />

      <EventShareModal
        container={container}
        selectedEventVideo={eventVideos[videoIndx]}
        open={openEventShareModal}
        onClose={() => {
          setOpenEventShareModal(false);
        }}
        onOpenHashtag={() => {
          // setOpenEventShareModal(false);
          setOpenHashtagModal(true);
        }}
        onSuccessSave={(description, hashtagIds) => {
          const videos = eventVideos;
          videos[videoIndx] = {
            ...videos[videoIndx],
            description,
            hashtags: _.filter(hashtags, (tag) =>
              _.includes(hashtagIds, tag.id)
            ),
          };
          setEventVideos([...videos]);
        }}
      />
      {openHashtagModal && (
        <HashtagModal
          container={container}
          open={openHashtagModal}
          onClose={() => {
            setOpenHashtagModal(false);
          }}
        />
      )}
    </>
  );
};
