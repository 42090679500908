import {
  ColdTemp,
  HotTemp,
  NormalTemp,
  Typography,
} from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import React from "react";

interface TempStatusProps {
  temp: number;
  isCelcius?: boolean;
}

const TempStatus = ({ temp, isCelcius }: TempStatusProps) => {
  const tempIcon =
    temp >= 70 ? (
      <HotTemp
        style={{
          width: 14,
          height: 14,
        }}
      />
    ) : temp >= 0 ? (
      <NormalTemp
        style={{
          width: 14,
          height: 14,
        }}
      />
    ) : (
      <ColdTemp
        style={{
          width: 14,
          height: 14,
        }}
      />
    );

  const tempString = isCelcius
    ? `${temp}°C`
    : `${((temp * 9) / 5 + 32).toFixed(0)}°F`;

  return (
    <>
      {tempIcon}
      <Typography variant="Caption" color={LightColors.primary["1"]}>
        {tempString}
      </Typography>
    </>
  );
};

export default TempStatus;
