import React from "react";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { LightColors } from "@thingsw/pitta-modules";
import { Typography } from "@thingsw/pitta-design-system";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  warningDiv: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    padding: "6px 12px",
    marginBottom: 16,
    backgroundColor: LightColors.secondary["18"],
    borderRadius: 4,
  },
  errorDiv: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    padding: "6px 15px",
    marginBottom: 16,
    backgroundColor: LightColors.secondary["14"],
    borderRadius: 4,
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
}));

interface BatteryAlertProps {
  isCharging: boolean;
  isOutputPowerCutOff: boolean;
  isHighTemperature: boolean;
  isLowTemperature: boolean;
  isLowCapacity: boolean;
  isLoading: boolean;
  isDisplayData: boolean;
  isHighVoltage: boolean;
  isLowVoltage: boolean;
}

const BatteryAlert = (props: BatteryAlertProps) => {
  const {
    isCharging,
    isOutputPowerCutOff,
    isHighTemperature,
    isLowTemperature,
    isLowCapacity,
    isLoading,
    isDisplayData,
    isHighVoltage,
    isLowVoltage,
  } = props;

  const classes = useStyles(props);
  const { t } = useTranslation();

  if (isOutputPowerCutOff && isCharging) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
            dangerouslySetInnerHTML={{
              __html: t("Low Output power Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isOutputPowerCutOff && !isCharging) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
          >
            [ {t("Output power cut-off")} ]
          </Typography>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
          >
            {t("The output voltage is too low_")}
          </Typography>
        </div>
      </div>
    );
  }

  if (isHighTemperature) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
            dangerouslySetInnerHTML={{
              __html: t("High temp Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isLowVoltage) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
            dangerouslySetInnerHTML={{
              __html: t("Low Input power Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isHighVoltage) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
            dangerouslySetInnerHTML={{
              __html: t("High Input power Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isLowCapacity && !isCharging) {
    return (
      <div className={classes.warningDiv}>
        <ReportProblemIcon htmlColor={LightColors.secondary["17"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["17"]}
            dangerouslySetInnerHTML={{
              __html: t("Low capacity Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isLowTemperature && isCharging) {
    return (
      <div className={classes.errorDiv}>
        <ErrorOutlineIcon htmlColor={LightColors.secondary["11"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["11"]}
            dangerouslySetInnerHTML={{
              __html: t("Low temp_Cut off Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isLowTemperature && !isCharging) {
    return (
      <div className={classes.warningDiv}>
        <ReportProblemIcon htmlColor={LightColors.secondary["17"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["17"]}
            dangerouslySetInnerHTML={{
              __html: t("Low temp Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (isDisplayData && isLoading) {
    return (
      <div className={classes.warningDiv}>
        <ReportProblemIcon htmlColor={LightColors.secondary["17"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["17"]}
            dangerouslySetInnerHTML={{
              __html: t("Data updates Alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  if (!isDisplayData && isLoading) {
    return (
      <div className={classes.warningDiv}>
        <ReportProblemIcon htmlColor={LightColors.secondary["17"]} />
        <div className={classes.textDiv}>
          <Typography
            variant="SmallBold"
            htmlColor={LightColors.secondary["17"]}
            dangerouslySetInnerHTML={{
              __html: t("Connecting alert").replaceAll("\n", "<br/>"),
            }}
          />
        </div>
      </div>
    );
  }

  return <></>;
};

export default BatteryAlert;
