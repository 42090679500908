import React, { useMemo } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { detect } from "detect-browser";

import CloseIcon from "@material-ui/icons/Close";
import { Webviewer } from "@thingsw/pitta-modules";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  contentRoot: {
    display: "flex",
    flex: 1,
    flexDirection: "column",

    justifyContent: "center",
  },
  modalBottom: {
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  modalContentWrap: {
    border: "none",
    padding: "0px 16px 24px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  step0Content: {
    justifyContent: "center",
  },
  btn: {
    height: 48,
    flex: 1,
  },
  numberDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contentWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  contentWrap34: {},
  content: {},
  webBtnDiv: {
    marginTop: theme.spacing(5.75),
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  open: {
    border: "none",
    zIndex: 99999999,
  },
  modalTitleDiv: {    
    backgroundColor: (props: any) => props.colors.primary["0"],
    ...(theme.direction === "rtl"
      ? { padding: "1px 11px 0 44px" }
      : { padding: "1px 44px 0 11px" }),
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  // mantis - 10781, iphone 6s에서 이미지와 텍스트 겹치는이슈 수정(Leehj)
  clear: {
    clear: "both",
  },
}));

interface EventMapOnboardingMobileProps {
  eventMapStep: number;
  onNext: () => void;
  onBack: () => void;
  onTourClose: () => void;
}

export const EventMapOnboardingMobile = ({
  eventMapStep,
  onNext,
  onTourClose,
  onBack,
}: EventMapOnboardingMobileProps) => {  
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ color, colors });

  const app = useMemo(() => {
    const browser = detect()?.name;
    return browser === "ios-webview" || browser === "chromium-webview";
  }, []);

  const pageNumMarkup = useMemo(() => {
    if (eventMapStep === 1) {
      return "1/4";
    } else if (eventMapStep === 2) {
      return "2/4";
    } else if (eventMapStep === 3) {
      return "3/4";
    } else if (eventMapStep === 4) {
      return "4/4";
    }
  }, [eventMapStep]);

  const contentMarkup = useMemo(() => {
    let content = <></>;
    let title = "";
    if (eventMapStep === 1) {
      content = (
        <img
          srcSet="/images/eventMaponboarding_mo.png 1x"
          alt="eventMaponboarding"
          width="89%"
          style={{ marginLeft: 16 }}
        />
      );
      title = "Event map collects_";
    }
    if (eventMapStep === 2) {
      content = (
        <img
          srcSet="/images/eventMaponboarding2_mo.png 1x"
          alt="eventMaponboarding2"
          width="89%"
          // mantis - 10781, iphone 6s에서 이미지와 텍스트 겹치는이슈 수정(Leehj)
          style={{ overflow: "hidden" }}
        />
      );
      title = "Share your Live_";
    } else if (eventMapStep === 3) {
      content = (
        <img
          srcSet="/images/eventMaponboarding3_mo.png 1x"
          alt="eventMaponboarding3"
          width="89%"
        />
      );
      title = "You can check_";
    } else if (eventMapStep === 4) {
      content = (
        <img
          srcSet="/images/eventMaponboarding4_mo.png 1x"
          alt="eventMaponboarding4"
          width="72%"
        />
      );
      title = "You can filter_";
    }
    return (
      <div className={classes.root}>
        <div className={classes.contentRoot}>
          <div className={clsx(classes.contentWrap, classes.contentWrap34)}>
            {content}
          </div>
        </div>

        <div
          className={clsx(classes.numberDiv, {
            [classes.clear]: eventMapStep === 2,
          })}
        >
          <div style={{ height: 112 }}>
            <Typography
              category="Default"
              variant="Body"
              className={classes.content}
            >
              {t(title)}
            </Typography>
          </div>

          <Typography category="Default" variant="Small">
            {pageNumMarkup}
          </Typography>
          <div style={{ display: "flex", width: "100%", marginTop: 16 }}>
            {eventMapStep === 1 ? (
              <div
                style={{
                  flex: 1,
                  padding: "8px 16px",
                  border: "1px solid transparent",
                }}
              />
            ) : (
              <Button
                color="primary"
                variant="outlined"
                onClick={onBack}
                className={classes.btn}
              >
                {t("Back")}
              </Button>
            )}

            <div style={{ width: 8 }} />
            <Button color="primary" onClick={onNext} className={classes.btn}>
              {eventMapStep === 4 ? t("OK") : t("Next")}
            </Button>
          </div>
        </div>
      </div>
    );
  }, [
    classes.btn,
    classes.clear,
    classes.content,
    classes.contentRoot,
    classes.contentWrap,
    classes.contentWrap34,
    classes.numberDiv,
    classes.root,
    eventMapStep,
    onBack,
    onNext,
    pageNumMarkup,
    t,
  ]);

  return (
    <Modal
      open
      mobile={mobile}
      onClose={onTourClose}
      // onClickPositive={onNext}
      // onClickNegative={onBack}
      heading={"     "}
      closeLeft={app}
      headingIcon={
        mobile && (
          <div>
            <IconButton onClick={onTourClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )
      }
      titleClassName={classes.modalTitleDiv}
      actionClassName={classes.modalBottom}
      contentClassName={clsx(
        classes.modalContentWrap,
        eventMapStep === 1 && classes.step0Content
      )}
      content={contentMarkup}
      // RButton={
      //   eventMapStep === 1
      //     ? t("Next")
      //     : eventMapStep === 4
      //     ? t("OK")
      //     : t("Next")
      // }
      // LButton={eventMapStep >= 2 ? t("Back") : ""}
      btnNoMargin={eventMapStep === 1}
      close
      // btnCenter
      fullSize={mobile}
      className={classes.open}
    />
  );
};
