import { detect } from "detect-browser";
import { useState, useEffect } from "react";

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      let timeout = 0;
      const ios = detect()?.name === "ios-webview";
      if (navigator.userAgent.indexOf("LM-Q815K") > -1) {
        timeout = 100;
      }
      // Set window width/height to state
      setTimeout(() => {
        setWindowSize({
          width: ios ? window.outerWidth : window.innerWidth,
          height: ios ? window.outerHeight : window.innerHeight,
        });
      }, timeout);
    }

    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(document.body);
      return () => {
        resizeObserver.unobserve(document.body);
      };
    } else {
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
