import { makeStyles, Theme } from "@material-ui/core";
import { Typography } from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TempHistoryDayChart from "./TempHistoryDayChart";
import TempHistoryWeekChart from "./TempHistoryWeekChart";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "24px 16px",
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  degree: {
    width: 30,
    height: 20,
    borderRadius: 4,
    border: `1px solid ${LightColors.primary["4"]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    color: LightColors.primary["1"],
    lineHeight: "16px",
    cursor: "pointer",
  },
  toggleBg: {
    borderRadius: 9,
    backgroundColor: LightColors.primary["6"],
    display: "flex",
    padding: 2,
    gap: 2,
    marginBottom: 12,

    "& > button": {
      width: "100%",
      minHeight: 30,
      height: 30,
      border: "none",
      cursor: "pointer",
      borderRadius: 9,
      color: LightColors.primary["1"],
    },
  },
  activated: {
    boxShadow:
      "0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12)",
    backgroundColor: "white",
  },
  inactivated: {
    backgroundColor: LightColors.primary["6"],
    boxShadow: "none",
  },
  chartDiv: {
    minHeight: 340,
    minWidth: 343,
    width: "100%",
    borderRadius: 12,
    border: `1px solid ${LightColors.primary["6"]}`,
    padding: "20px 10px",
  },
}));

interface BatteryTempHistoryProps {
  isCelcius: boolean;
  setIsCelcius: React.Dispatch<React.SetStateAction<boolean>>;
}

const BatteryTempHistory = (props: BatteryTempHistoryProps) => {
  const { isCelcius, setIsCelcius } = props;

  const classes = useStyles(props);
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState<"day" | "week">("day");

  const toggleDisplayMode = (mode: "day" | "week") => {
    setDisplayMode(mode);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="H6" htmlColor={LightColors.primary["1"]}>
          {t("Temperature History")}
        </Typography>
        <div
          onClick={() => setIsCelcius((prev) => !prev)}
          className={classes.degree}
        >
          {isCelcius ? "°C" : "°F"}
        </div>
      </div>
      <Typography
        variant="Caption"
        style={{
          marginBottom: 16,
        }}
        htmlColor={LightColors.primary["2"]}
      >
        {t("The graph displays temperature")}
      </Typography>
      <div className={classes.toggleBg}>
        <button
          className={clsx({
            [classes.activated]: displayMode === "day",
            [classes.inactivated]: displayMode === "week",
          })}
          onClick={() => toggleDisplayMode("day")}
        >
          <Typography variant="SmallBold">{t("Last 24 Hours")}</Typography>
        </button>
        <button
          onClick={() => toggleDisplayMode("week")}
          className={clsx({
            [classes.activated]: displayMode === "week",
            [classes.inactivated]: displayMode === "day",
          })}
        >
          <Typography variant="SmallBold">{t("Last 7 Days")}</Typography>
        </button>
      </div>
      <div className={classes.chartDiv}>
        {displayMode === "day" && <TempHistoryDayChart isCelcius={isCelcius} />}
        {displayMode === "week" && (
          <TempHistoryWeekChart isCelcius={isCelcius} />
        )}
      </div>
    </div>
  );
};

export default BatteryTempHistory;
