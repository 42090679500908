import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";
import { LightColors } from "@thingsw/pitta-modules";

interface EmptyCamerasProps {
  variant?: "default" | "small";
  mode?:
    | "camera"
    | "member"
    | "routes"
    | "geofences"
    | "notifications"
    | "geofence-alert"
    | "liveView";
  noCamTextClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ variant }: EmptyCamerasProps) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...(variant === "small"
      ? { margin: "auto 0px" }
      : { margin: theme.spacing(2, 0) }),
    flexGrow: 1,
  }),
  noItemDiv: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? { marginTop: "-14px" }
      : {
          marginTop: "18.75vh",
        },
  noCamText: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? {}
      : {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        },
  noItemIcon: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? {
          width: 140,
          height: 140,
        }
      : {
          width: 200,
          height: 200,
        },
  textCenter: {
    textAlign: "center",
    padding: "0 16px",
  },
}));

export const EmptyItemsUXUI = (props: EmptyCamerasProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { variant, mode, noCamTextClassName } = props;

  return (
    <div className={classes.root}>
      <ReactSVG
        src="/images/noItem-uxui.svg"
        className={classes.noItemDiv}
        beforeInjection={(svg) => {
          svg.classList.add(..._.split(classes.noItemIcon, " "));
        }}
      />
      {mode === "notifications" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={clsx(classes.noCamText, noCamTextClassName)}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No notifications yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Notifications will show_")}
          </Typography>
        </>
      )}
      {mode === "geofences" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No geofences yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Geofences will show_")}
          </Typography>
        </>
      )}
      {mode === "routes" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No routes to_")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("It will show_")}
          </Typography>
        </>
      )}
      {mode === "member" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No members yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Members will show_")}
          </Typography>
        </>
      )}
      {mode === "geofence-alert" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No geofence alert")}
          </Typography>
        </>
      )}
      {(mode === "camera" || mode === undefined) && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No cameras yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Cameras will show_")}
          </Typography>
        </>
      )}
      {mode === "liveView" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No cameras to_")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("You have added_")}
          </Typography>
        </>
      )}
    </div>
  );
};
