import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  disConnectModal: {
    width: "350px",
  },
  disConnectModalButton: {
    width: "100%",
  },

  titleDiv: {
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface BatteryInuseModalProps {
  open: boolean;
  onClickPositive: () => void;
}

const BatteryInuseModal = (props: BatteryInuseModalProps) => {
  const { open, onClickPositive } = props;

  const { t } = useTranslation();

  const classes = useStyles(props);

  return (
    <Modal
      mobile
      open={open}
      className={classes.disConnectModal}
      fullWidthBtn
      RButtonClassName={classes.disConnectModalButton}
      RButton="OK"
      onClickPositive={onClickPositive}
      content={
        <div>
          <div className={classes.textDiv}>
            <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
              {t("A connection is already active")}
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export default BatteryInuseModal;
